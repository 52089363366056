function generateRoutes(permissions,translate){
 
    var routes = [];
    
    routes.push({
        collapse: true,
        name: 'Cursos', 
        icon: "fas fa-graduation-cap text-info",
        state: "dashboardsCollapse",
        views: [
            {
                path: "/course/newcourse",
                name: 'Novo curso',
                layout: "/admin"
            },
            {
                path: "/course/",
                name: 'Listar cursos',
                layout: "/admin"
            } 
        ]
    });

    routes.push({path:"/users",name:'Usuários',icon:"fas fa-user text-green",layout:"/admin"});
    routes.push({path:"/permission",name:'Permissões',icon:"fas fa-user-shield text-green",layout:"/admin"});
    //routes.push({path:"/license",name:'Licenças',icon:"fas fa-key text-green",layout:"/admin"});
    //routes.push({path:"/assessment_methods",name:'Métodos de avalição',icon:"fas fa-key text-green",layout:"/admin"});
 
    return routes;
  
  }
     
  export default generateRoutes;
  