import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
const arrayMove = require('array-move');
const uuidv4 = require('uuid/v4');


const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,

  // change background colour if dragging
  //background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({/*background: isDraggingOver ? 'lightblue' : 'lightgrey',*/padding: grid,overflow: 'auto',});

class SpeechToText extends Component {

    constructor(props) {

        super(props);
        this.state = {};

        this.onDragEnd = this.onDragEnd.bind(this);
        this.add = this.add.bind(this);
        this.remove = this.remove.bind(this);

    }

    add(){

        this.props.state.dialogLesson.speechtotext.push({id:uuidv4(),text:'',answer:''});
        this.setState({a:''});

    }

    remove(index){

        this.props.state.dialogLesson.speechtotext.splice(index,1);
        this.setState({a:''});

    }

    onDragEnd(data){
       
        this.props.state.dialogLesson.speechtotext = arrayMove(this.props.state.dialogLesson.speechtotext,data.source.index,data.destination.index);
        this.setState({a:""});

    }
    
    render() {

        var speechtotext = this.props.state.dialogLesson.speechtotext;
        if(speechtotext === undefined){

            this.props.state.dialogLesson.speechtotext = [];
            speechtotext = this.props.state.dialogLesson.speechtotext;

        }

        return (
        <React.Fragment>

            {

                (speechtotext.length === 0) &&
                <React.Fragment>
                    <center><h4>Nada foi cadastrado.</h4></center>
                </React.Fragment>

            }
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable" direction="vertical">
                {(provided, snapshot) => (
                    <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps} >
                    {
                    
                        speechtotext.map((item, index) => (
                            <Draggable key={item.id+''} draggableId={item.id+''} index={index}>
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps}  style={getItemStyle(snapshot.isDragging,provided.draggableProps.style)} className="row">
                                        <div className="col-md-1" style={{display:'flex',justifyContent:'center',alignItems:'center'}}><i {...provided.dragHandleProps} style={{position:'absolute',top:'40px',left:'55px'}} className="fas fa-arrows-alt"></i></div>
                                        <div className="col-md-5">
                                            <h5>Texto:</h5>
                                            <input type="text" className="form-control-alternative form-control" value={item.text} onChange={(e) => { item.text = e.target.value; this.setState({a:''}); }}/>
                                        </div>
                                        <div className="col-md-5">
                                            <h5>Resposta:</h5>
                                            <input type="text" className="form-control-alternative form-control" value={item.answer} onChange={(e) => { item.answer = e.target.value; this.setState({a:''}); }}/>
                                        </div>
                                        <div className="col-md-1" style={{display:'flex',justifyContent:'left'}}><i onClick={() => this.remove(index)} style={{cursor:'pointer',position:'absolute',top:'40px',left:'0px'}} className="far fa-trash-alt"></i></div>
                                    </div>
                                )}
                            </Draggable>
                        ))
                    
                    }
                    {provided.placeholder}
                    </div>
                )}
                </Droppable>
            </DragDropContext>

            <center><button style={{width:'130px'}} color="primary" onClick={() => this.add()} className="btn btn-sm btn-secondary"><i className="fas fa-plus"></i> Adicionar</button></center>

        </React.Fragment>
        );
    }

}

export default SpeechToText;




/*import React from "react";
import {DragDropContext,Droppable,Draggable} from 'react-beautiful-dnd';
const uuidv4 = require('uuid/v4');
const arrayMove = require('array-move'); 

class SpeechToText extends React.Component {

    constructor(props){
        
        super(props);
        this.state = {}; 
        
        this.add = this.add.bind(this);
        this.remove = this.remove.bind(this);

        window.speechtotext = this;
    
    }


    add(){

        this.props.state.dialogLesson.speechtotext.push({text:'',answer:''});
        this.setState({a:''});

    }

    remove(index){

        this.props.state.dialogLesson.splice(index,1);
        this.setState({a:''});

    }

    render() {

        var speechtotext = this.props.state.dialogLesson.speechtotext;
        if(speechtotext === undefined){

            this.props.state.dialogLesson.speechtotext = [];
            speechtotext = this.props.state.dialogLesson.speechtotext;

        }

        console.log(speechtotext);

        return (
        <React.Fragment>
            
            {

                (speechtotext.length === 0) &&
                <React.Fragment>
                    <center><h4>Nada foi cadastrado.</h4></center>
                </React.Fragment>

            }

          
 
            <DragDropContext onDragEnd={(e) => { console.log(e); }}>
                <Droppable droppableId={"drop_colum_teste5"} direction="vertical" type="cards">
                    {

                        (provided3) => (
                        <div style={{ }} ref={provided3.innerRef} {...provided3.droppableProps} >
                            {

                                speechtotext.map((value,index2) => {
                                    
                                    var id = uuidv4();
                                    if(value.id === undefined){ value.id = id; }

                                    return(
                                        <Draggable key={value.id} draggableId={'opportunitysa_'+value.id}  index={index2}>
                                        {
                                            (provided3) => (
                                            <div {...provided3.draggableProps} ref={provided3.innerRef} className="" style={{marginBottom:'10px'}}>
                                                <div className="col-md-1" style={{display:'flex',justifyContent:'center',alignItems:'center'}}><i {...provided3.dragHandleProps} style={{ }} className="fas fa-arrows-alt"></i></div>
                                                <div className="col-md-5">
                                                    <h5>Texto:</h5>
                                                    <input type="text" className="form-control-alternative form-control" value={value.text} onChange={(e) => { value.text = e.target.value; this.setState({a:''}); }}/>
                                                </div>
                                                <div className="col-md-5">
                                                    <h5>Resposta:</h5>
                                                    <input type="text" className="form-control-alternative form-control" value={value.answer} onChange={(e) => { value.answer = e.target.value; this.setState({a:''}); }}/>
                                                </div>
                                                <div className="col-md-1" style={{display:'flex',justifyContent:'left'}}><i style={{position:'absolute',top:'40px',left:'0px'}} className="far fa-trash-alt"></i></div>
                                            </div>)
                                        }
                                        </Draggable>
                                    )

                                })

                            }

                            {provided3.placeholder}

                        </div>)

                    }
                </Droppable>
            </DragDropContext>
            
            <center><button style={{width:'130px'}} color="primary" onClick={() => this.add()} className="btn btn-sm btn-secondary"><i className="fas fa-plus"></i> Adicionar</button></center>

        </React.Fragment>);

    }

}
export default SpeechToText;
*/