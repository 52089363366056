import React from 'react';
import Main from './views/Main.js'
import api from './Api.js';
import $ from 'jquery';
import axios from "axios";

//import LogRocket from 'logrocket';
//LogRocket.init('ejs9so/csplus-center');
import Cookies from 'universal-cookie';

const Swal = require('sweetalert2');
const cookies = new Cookies();

window.swal = Swal;
window.cookies = cookies;
class App extends React.Component {

  constructor(props){

    super(props);
 
    var session = cookies.get('session');
    var userId = cookies.get('userId'); 

    if(session !== undefined && userId !== undefined){ 
       
      sessionStorage.setItem('session',session);
      sessionStorage.setItem('userId',userId);
  
    }

    
    session = sessionStorage.getItem('session');
    userId = sessionStorage.getItem('userId');

    //caso não tenha, o session é vazio, isso faz o cara ir para página de login
    if(session === null || userId === null){ session = ""; userId = ""; }
 
    this.state = {systemOK:false,userId:userId,session:session,carregouIp:false,darkMode:false,menuMin:false,lessonsMinified:true,languageSelected:"pt-br",languages:[],languageTexts:{}}; 

    this.verificationInitial = this.verificationInitial.bind(this);
    this.getCurrentLanguage = this.getCurrentLanguage.bind(this);
    this.language = this.language.bind(this);
    this.removeSession = this.removeSession.bind(this);
    
     
    window.app = this;

  }
  
  //retorna os testos da linguagem atual
  language(term){ 

    if(term === undefined){ return this.state.languageTexts; }else{ return this.state.languageTexts[term]; }

  }
  
  async verificationInitial(){
       

    console.log('verificationInitial');
    var dm = 'center';

    if(window.location.origin !== "http://localhost:3000"){

      dm = window.location.hostname.split('.')[0];

    }

    //var url = "https://geeksclub.s3.sa-east-1.amazonaws.com/serverlist/"+dm+".json";

    var bk = 'https://lmsapi1.geeks.club/'+dm;

    sessionStorage.setItem('backend', bk); 
    window.backend = bk;

    this.setState({carregouIp:true}); 
    this.verify(1);

    /*var a = await axios.get(url).then((response) => {

      console.log('response');
      console.log(response);

      if(window.location.origin === "http://localhost:3000"){

        response.data.url = 'http://localhost:3001/center';

      }
      

      sessionStorage.setItem('backend', response.data.url); 
      window.backend = response.data.url;

      this.setState({carregouIp:true}); 
      this.verify(1);
      return true;

    }).catch((error) => {

      console.error(error);
      return false;

    });

    console.log('a');
    console.log(a);*/

    return true;

  }

  //Para exibir o preload
  loading(n){}

  //Parar deslogar o usuario do sistema
  removeSession(){

    console.log('removeu tudo');
    sessionStorage.removeItem('session');
    sessionStorage.removeItem('userId');
    delete window.app.state.dataUser;
    delete window.app.state.user;
    this.setState({session:'',userId:''});

  }

  //para verificar no servidor se ele esta logado.
  verify(pass){

   // if(this.state.session !== "" && this.state.carregouIp === true){ pass = true; }
     
    api.verifyAcess({}).then((res)=>{

      if(this.state.user === undefined){ 

        this.setState({user:res.data}); 
        //LogRocket.identify('csplus_center_'+res.data.id,{name: res.data.name+' '+res.data.lastName,email:res.data.email,subscriptionType:'pro'});
      
      }
      
    }).catch(error=>{
        console.log(error.data);
        console.log(error);
        console.log(error.response);
        if(error.response !== undefined){if(error.response.status !== undefined){if(error.response.status === 401){  this.removeSession();  }}}
    });

    
 

  }


  //Para carregas as linguagens disponíveis no sistema.
  async getAllLanguage(){
    var language = await api.listLanguages().then((res) => {
      this.setState({languages:res.data})
      return true;
    }).catch(error => {
      console.log(error.data);
      console.log(error);
      console.log(error.response);
      return false;
    });
    return language;
  }


  //para retornar os dados da linguagem atual selecionaand
  getCurrentLanguage(){
    var language;
    for(let i = 0; i < this.state.languages.length; i++){ if(this.state.languages[i].id === this.state.languageSelected){ language = this.state.languages[i]; }}
    return language;
  }

  //para buscar o arquivo da linguagem no sistema
  async getLanguageTexts(nameFile,id){
    console.log(nameFile);
    
    this.setState({languageSelected:id});

    var a = await api.languageText(nameFile).then((res) => {
      this.setState({languageTexts:res.data});
      return true;
    }).catch(error => {
      console.log(error.data);
      console.log(error);
      console.log(error.response);
      return false;
    });
    return a;
  }


  async componentDidMount(){

    //para verificar o backend
    var a = await this.verificationInitial();

    //para pegar as linguagens disponíveis no sistema 
    var b = await this.getAllLanguage();

    //para carregar a linguagem padrão
    var c = await this.getLanguageTexts('pt-br.json','pt-br');

    //caso esteja tudo correto, exibe a página
    if(a === true && b === true && c === true){

      this.setState({systemOK:true});

    }else{

      console.log("Verificação do brancked: "+a);
      console.log("Verificação do languages: "+b);
      console.log("Baixou arquivo de linguagem: "+c);

    }

  }
 

  render() {

    console.log('this.state.systemOK',this.state.systemOK);

    return (

      <React.Fragment>
        {
          
          (this.state.systemOK === true) &&
          <Main loading={ (n) => this.loading(n) }  setAppState={ (n) => this.setState(n) }  getAppState={ () => this.state } />
        
        }

      </React.Fragment>

    );
  }


}

export default App;