import React from "react";
import {Modal,Button,Card,CardHeader,Input,Row,Col} from "reactstrap";
import SimpleHeader from "./../../../components/Headers/SimpleHeader.jsx";
import api from "./../../../Api.js";
import { SortablePane, Pane } from 'react-sortable-pane';

import NotificationAlert from "react-notification-alert";
import "./../../../assets/vendor/quill/dist/quill.core.css";
import $ from 'jquery';
import ReactQuill from 'react-quill';

const uuidv4 = require('uuid/v4');

const modules = {
            toolbar: [
              ["bold", "italic"],
              ["image"],
              [ 
              ]
            ]
          };

class MountQuiz extends React.Component {

	constructor(props){
		
		super(props);
		this.state = {orderAnswer:[],listAnswer:[],orderQuestion: [],listQuestion:[],showQuestionPane:false,openDialogRemoveQuestion:false,loadCourse:false,loadQuiz:false,loadQuestions:false,quiz:{},questions:[],reactQuillText:"",showTablePane:false};

		this.newQuestion = this.newQuestion.bind(this);
		this.loadQuestions = this.loadQuestions.bind(this);
		this.getQuiz = this.getQuiz.bind(this);
		this.editQuestion = this.editQuestion.bind(this);
		this.removeQuestionModal = this.removeQuestionModal.bind(this);
		this.removeQuestionApi = this.removeQuestionApi.bind(this);
		this.handleReactQuillChange = this.handleReactQuillChange.bind(this);
		this.handleReactQuillChangeanswer = this.handleReactQuillChangeanswer.bind(this);
		this.changeOrderAnswer = this.changeOrderAnswer.bind(this);
		this.addAnswer = this.addAnswer.bind(this); 
		this.removeAnswer = this.removeAnswer.bind(this);
		this.changeOrderQuestion = this.changeOrderQuestion.bind(this);
		this.addQuestion = this.addQuestion.bind(this); 
		this.removeQuestion = this.removeQuestion.bind(this);
		this.reorderAnswer = this.reorderAnswer.bind(this);
		this.changeRespCor = this.changeRespCor.bind(this);

		window.mountquiz = this;

	}

	//Para pegar os dados do curso
	async getCourse(){

	  await api.getCourse(this.props.match.params.course).then((res) => {
	        
	    this.setState({course:res.data,loadCourse:true});

	  }).catch(error => {

	    console.log(error.data);
	    console.log(error);
	    console.log(error.response);

	    if(error.response.status === 404){ this.props.history.push('/'); }

	  });

	}

	//Para pegar os dados do quiz
	async getQuiz(){

		console.log('Quiz: '+this.props.match.params.quiz)
		await api.getQuiz(this.props.match.params.quiz).then((res) => {
		    
			this.setState({quiz:res.data,loadQuiz:true});
			return true;

		}).catch(error => {

			console.log(error.data);
			console.log(error);
			console.log(error.response);

			if(error.response.status === 404){ this.props.history.push('/'); }
			return false;

		});

	}

	//Para cadastrar uma nova questão
	async newQuestion(){

		await api.postQuestion({quiz:this.props.match.params.quiz}).then((res) => {

			var questions = this.state.questions;
			questions.push(res.data.question);

			this.addQuestion(res.data.question);
			this.setState({questions:questions});
			this.editQuestion(res.data.question.id);
		
			return true;

		}).catch(error => {

			console.log(error.data);
			console.log(error);
			console.log(error.response);
  
		});

	}

	//Para carregar as questões do quiz
	async loadQuestions(addPane = true){

		await api.getQuizQuestions(this.props.match.params.quiz).then((res) => {
 
			if(addPane === true){
				
				for(var i = 0; i < res.data.length; i++){ this.addQuestion(res.data[i]); }

			}
			
			this.setState({questions:res.data,loadQuestions:true,showQuestionPane:true});

			if(this.state.editQuestion === undefined){

				if(res.data.length !== 0){ this.editQuestion(res.data[0].id) }

			} 

		}).catch(error => {

			console.log(error.data);
			console.log(error);
			console.log(error.response);

			//if(error.response.status === 404){ this.props.history.push('/'); }

		});

	}

	//Para editar uma questão
	async editQuestion(id){

		$(".button_edit_question").fadeIn(0);
		$(".button_edit_question[idc="+id+"]").fadeOut(0);

		this.setState({editQuestionID:id});
 

		this.state.questions.forEach(function(item,index,array){ 

			if(parseInt(item.id) === parseInt(id)){
				
				this.setState({orderAnswer:[],listAnswer:[]});
				setTimeout(function(){  
				for(var i = 0; i < item.answer.length; i++){

					this.addAnswer(item.answer[i]);
						
				} }.bind(this), 100);

				this.setState({editQuestion:this.state.questions[index]});

			} 

		}.bind(this));

	}

	//Para remover uma questão
	async removeQuestionModal(id){ this.setState({openDialogRemoveQuestion:true,removeQuestion:id}); }

	async componentDidMount(){
  
    	await this.getCourse();
    	await this.loadQuestions();
    	await this.getQuiz();

	}

	async removeQuestionApi(){

		this.setState({openDialogRemoveQuestion:false});
		await api.deleteQuestion(this.state.removeQuestion).then((res) => {
		  
			this.loadQuestions(false);
			let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">A questão foi removida.</span></div>),type: 'success',icon: "fas fa-exclamation",autoDismiss: 7};
			this.refs.notificationAlert.notificationAlert(options);

			//Para remover o pane
			var idpane = $('.question_a[id_real='+this.state.removeQuestion+']').attr('idc');
			this.removeQuestion(idpane);
			this.reorderQuestion();

			//Verifica se a questão removida é a questão que esta sendo editada
			setTimeout(function(){   
			if(parseInt(this.state.removeQuestion) === parseInt(this.state.editQuestion.id)){

				if(this.state.questions[0] !== undefined){ this.editQuestion(this.state.questions[0].id); }

			}}.bind(this), 10);

		}).catch(error => {

			console.log(error.data);
			console.log(error);
			console.log(error.response);

			this.setState({loading:false});

			if(error.response.status === 400){

		  		let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Não foi possível remover a questão.</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
		  		this.refs.notificationAlert.notificationAlert(options);

			}

		});

  	}
 
  	async saveQuestion(){

  		this.setState({openDialogRemoveQuestion:false});

  		var questionData = JSON.parse(JSON.stringify(this.state.editQuestion));
  		delete questionData.id;
  		delete questionData.quiz;
  		delete questionData.updateDate;
  		delete questionData.createDate;
  		delete questionData.sequence;
 		
 		//para qpegar as questoes
 		var q = []
 		for(var i = 0; i < this.state.orderAnswer.length; i++){

 			var html = $('.paneUnit3Move[idc="'+this.state.orderAnswer[i]+'"]').find('.ql-editor').html();
 			q.push(html);

 		}

  		questionData.answer = JSON.stringify(q);
  		questionData.correctAnswer = $("#respostaCorreta").val()

		await api.putQuestion(this.state.editQuestion.id,questionData).then((res) => {
		  
			this.loadQuestions(false);
			let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">As alterações foram salvas.</span></div>),type: 'success',icon: "fas fa-exclamation",autoDismiss: 7};
			this.refs.notificationAlert.notificationAlert(options);

		}).catch(error => {

			console.log(error.data);
			console.log(error);
			console.log(error.response);

			this.setState({loading:false});

			if(error.response.status === 400){

		  		let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Não foi possível salvar a questão.</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
		  		this.refs.notificationAlert.notificationAlert(options);

			}

		});

  	}

  	//Para alterar o state da question
 	handleReactQuillChange(value,type){

 		var editQuestion = this.state.editQuestion;

 		if(type === "question"){ editQuestion.question = value; }
		if(type === "messageForCorrectAnswer"){ editQuestion.messageForCorrectAnswer = value; }
		if(type === "messageForIncorrectAnswer"){ editQuestion.messageForIncorrectAnswer = value; }

		console.log(value);

		this.setState({editQuestion:editQuestion});     

  	};
 
 	//para alterar o state da repsosta
 	handleReactQuillChangeanswer(value,index){

 		var editQuestion = this.state.editQuestion;
 		editQuestion.answer[index] = value;

 		this.setState({editQuestion:editQuestion});

 	}

  	updateOrderQuestion(order){

  		var questions = this.state.questions;
  		questions.forEach(function(item,index,array){ questions[index].sequence = order.indexOf(''+item.id); });
  	  	this.setState({questions:questions});

  	}

  	//para remover uma resposta
  	/*removeAnswer(index){

  		var editQuestion = this.state.editQuestion;
  		editQuestion.answer.splice(index,1);
  		this.setState({editQuestion:editQuestion});

  	}*/

  	changeOrderAnswer(order){

	 	this.setState({orderAnswer:order});
	 	this.reorderAnswer();

  	}





	addAnswer(value) {

		console.log(this.state.listAnswer.length);

		if(parseInt(this.state.listAnswer.length) >= 4){ 
			
			let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Limite de resposta atingindo.</span><span data-notify="message">Você pode cadastrar no máximo 4 respostas por questão.</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
			this.refs.notificationAlert.notificationAlert(options);
				
			return false; 
		}

		if(value === undefined){ value = ""; }

		window.$('.__resizable_base__').remove();
	    const orderAnswer = this.state.orderAnswer;
	  
	 	var id = uuidv4();
	    this.state.listAnswer.push(
	    <Pane style={{backgroundColor:"#fff"}} resizable={{x:false,y:false,xy:false}} key={id} defaultSize={{ width: '100%', height: 200 }}>

	    	<div className="answer_a paneUnit3Move" idc={id} style={{marginTop:'10px',marginLeft:'30px',marginRight:'30px'}}>
				<i style={{marginLeft:'0px',color:'#3a3a63'}} className="fas fa-arrows-alt movedor"></i> Resposta: <font className="n">{ (this.state.orderAnswer.length + 1) }</font>
				<Button style={{float:'right'}} color="danger" size="sm" type="button" onClick={ () => this.removeAnswer(id) } ><i className="far fa-trash-alt"></i> Remover</Button>
			 	<div style={{marginTop:'10px'}}> {<ReactQuill 	modules={{toolbar: [
															[{font:[]},{size:[]}],
															['bold','italic','underline','strike'],
															[{color:[]},{'background':[]}],
															[{script:'super'},{'script':'sub'}],
															[{header:'1'},{header:'2'},'blockquote','code-block'],
															[{list:'ordered'},{list:'bullet'},{indent:'-1'},{indent:'+1'}],
															['direction',{align:[]}],
															['link','image','video','formula'],
															['clean']
														]
														
														}}  defaultValue={value} onChange={(value) => this.handleReactQuillChangeanswer(value,'index') }  theme="snow"  />}</div>
		 	</div>

    	</Pane>);

		orderAnswer.push(id);
	    this.setState({listAnswer:this.state.listAnswer, orderAnswer:orderAnswer });
	    window.$('.__resizable_base__').remove();

	}

  	removeAnswer(id){

	  	window.$('.__resizable_base__').remove();
	    const index = this.state.orderAnswer.indexOf(id);
	    const pane = this.state.listAnswer.splice(index, 1);
	    const orderAnswer = this.state.orderAnswer.filter(o => o !== pane[0].key);

	    this.setState({listAnswer:this.state.listAnswer,orderAnswer});
	    window.$('.__resizable_base__').remove();

	    setTimeout(function(){ this.reorderAnswer(); }.bind(this), 50);
 
  	}

  	//Parar mudar o numero da questão
  	reorderAnswer(){

  		for(var i = 0; i < this.state.orderAnswer.length; i++){ $(".answer_a[idc="+this.state.orderAnswer[i]+"]").find('.n').html(i+1); }

  	}
 	
 	//Ao mudar a ordem das questões
  	changeOrderQuestion(order){

	 	this.setState({orderQuestion:order});
	 	this.reorderQuestion();

  	}


  	//Para adicionar uma nova questão no pane
	addQuestion(value) {


		window.$('.__resizable_base__').remove();
	    const orderQuestion = this.state.orderQuestion;
	  
	 	var id = uuidv4();
	    this.state.listQuestion.push(
	    <Pane resizable={{x:false,y:false,xy:false}} className="paneUnit2Move" key={id} defaultSize={{width:'100%',height:36}}  >
						                     
			<Row className="align-items-center question_a" idc={id} id_real={value.id} style={{marginTop:'5px'}}>
				<Col md={8}><i style={{marginLeft:'20px',color:'#3a3a63'}} className="fas fa-arrows-alt movedor"></i> Questão <font className="n">{parseInt(value.sequence)+1}</font></Col>
				<Col md={4}>
					<center>
						<Button className="button_edit_question" idc={value.id} color="secondary" size="sm" type="button" onClick={ () => this.editQuestion(value.id) }><i className="fas fa-pencil-alt"></i></Button>
						<Button color="danger" size="sm" type="button" onClick={ () => this.removeQuestionModal(value.id) }><i className="far fa-trash-alt"></i></Button>
					</center>
				</Col>
			</Row>
						                          
		</Pane>);

		orderQuestion.push(id);
	    this.setState({listQuestion:this.state.listQuestion, orderQuestion:orderQuestion });
	    window.$('.__resizable_base__').remove();

	}

	//Parar remover uma questão do pane
  	removeQuestion(id){

	  	window.$('.__resizable_base__').remove();
	    const index = this.state.orderQuestion.indexOf(id);
	    const pane = this.state.listQuestion.splice(index, 1);
	    const orderQuestion = this.state.orderQuestion.filter(o => o !== pane[0].key);

	    this.setState({listQuestion:this.state.listQuestion,orderQuestion});
	    window.$('.__resizable_base__').remove();

	    setTimeout(function(){ this.reorderAnswer(); }.bind(this), 50);
 
  	}

  	//Para reorder as questões no pane no banco
	reorderQuestion(){

  		for(var i = 0; i < this.state.orderQuestion.length; i++){ 

  			$(".question_a[idc="+this.state.orderQuestion[i]+"]").find('.n').html(i+1); 
  			var idreal = $(".question_a[idc="+this.state.orderQuestion[i]+"]").attr('id_real');
   		 
			  var secure = 0; 
  			while(true){
 
  				if(parseInt(this.state.questions[secure].id) === parseInt(idreal)){

					
						var questions = this.state.questions
						questions[secure].sequence = i;
						this.setState({questions:questions});
						break;

  				}
				
				secure++;
  				if(secure === 100){break;}

  			}

  		}

  		this.setState({a:'1'});

  		this.state.questions.forEach(function(item,index,array){

  			api.putQuestion(item.id,{sequence:item.sequence}).then((res) => {
			
			console.log(res.data);

			}).catch(error => {

				console.log(error.data);
				console.log(error);
				console.log(error.response);

				if(error.response.status === 400){

			  		let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Não foi possível atualizar a questão.</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
			  		this.refs.notificationAlert.notificationAlert(options);

				}

			});

  		}.bind(this));

  	}

  	changeRespCor(event){

  		var v = this.state.editQuestion;
  		v.correctAnswer = event.target.value;
  		this.setState({editQuestion:v});

  	}

	render(){

		var newalt2 = (this.state.listAnswer.length*200)+120;
		var style1 = {height:newalt2+'px'};

		return(<React.Fragment>
			<div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
				{ 

					(this.state.loadCourse === true && this.state.loadQuiz === true) && 
					<SimpleHeader cl="header header-dark bg-info pb-0 content__title content__title--calendar" name="" way={[{name:"Cursos",url:"/course"},{name:this.state.course.name,url:"/course/"+this.state.course.id},{name:'Quizzes',url:"/course/"+this.state.course.id+'/quizzes'},{name:this.state.quiz.name,url:"/course/"+this.state.course.id+"/quizzes/"+this.state.quiz.id}]} buttons={<React.Fragment></React.Fragment>} />

				}
				<Col md={12}>
					<Row>
					  	<Col xs="8">
						{	(this.state.editQuestion !== undefined) &&

							<React.Fragment>
					  		<CardHeader>
						  		<Card>
									<Col md={12} style={{marginBottom:'20px'}} idreal={this.state.editQuestion.id}>
								  		<h4 style={{marginTop:'10px'}}>Questão: <font className="n">{this.state.editQuestion.sequence+1}</font></h4>
								  		<ReactQuill modules={{toolbar: [
															[{font:[]},{size:[]}],
															['bold','italic','underline','strike'],
															[{color:[]},{'background':[]}],
															[{script:'super'},{'script':'sub'}],
															[{header:'1'},{header:'2'},'blockquote','code-block'],
															[{list:'ordered'},{list:'bullet'},{indent:'-1'},{indent:'+1'}],
															['direction',{align:[]}],
															['link','image','video','formula'],
															['clean']
														]
														
														}} value={this.state.editQuestion.question} onChange={(value) => this.handleReactQuillChange(value,'question') } theme="snow" />
								        <br/>
								        <Row hidden>
								        	<Col md={6}>
								        		<h4>Mensagem para resposta correta:</h4>
										        <ReactQuill modules={modules} value={this.state.editQuestion.messageForCorrectAnswer} onChange={(value) => this.handleReactQuillChange(value,'messageForCorrectAnswer') } theme="snow" /> 
								        	</Col>
								        	<Col md={6}>
								        		<h4>Mensagem para resposta incorreta:</h4>
										        <ReactQuill modules={modules} value={this.state.editQuestion.messageForIncorrectAnswer} onChange={(value) => this.handleReactQuillChange(value,'messageForIncorrectAnswer') } theme="snow" /> 
								        	</Col>
								        </Row>
							        </Col>
								</Card>
								<center>
									<Row>
										<Col md={5} style={{display:'flex'}}>

											<h4 style={{paddingTop:'10px',marginRight:'12px'}}>Resposta correta:</h4>
											<Input value={this.state.editQuestion.correctAnswer} onChange={ (event) => this.changeRespCor(event) } id="respostaCorreta" style={{width:'65px'}} className="form-control-alternative" type="select">
												{

													this.state.orderAnswer.map((value,index) => {
														
														return(<option value={(index)}>{(index+1)}</option>)

													})

												}
											</Input>
										</Col>
										<Col md={7}>
											<Button style={{float:'right',marginLeft:'10px'}} color="primary" type="button" onClick={ () => this.saveQuestion() }>Salvar questão</Button>
											<Button style={{float:'right'}} color="secondary" type="button" onClick={ () => this.addAnswer() }>Adicionar resposta</Button>
										</Col>
									</Row>	
								</center>
								<br/>
					  			{

					  				(this.state.orderAnswer.length === 0) &&
					  				<center>Nenhuma resposta foi cadastrada.</center>

					  			}
								</CardHeader>
	 							<div style={style1}>
							        <SortablePane dragHandleClassName="movedor" direction="vertical" margin={0} order={this.state.orderAnswer} onOrderChange={order => this.changeOrderAnswer(order)} >
						          	{this.state.listAnswer}
							        </SortablePane>
						        </div>

				  			</React.Fragment>
						}

						{

							(this.state.editQuestion === undefined) &&
							<React.Fragment>
								<center style={{marginTop:'50px'}}><h1>Adicione uma questão para começar.</h1></center>
							</React.Fragment>

						}
						
						</Col>
				  		<Col xs="4">
				  			<Card>
								<CardHeader>
					  			<Row>               
					  				<Col xs="6"><h3 className="mb-0">Lista de Questões</h3></Col>
					                <Col className="text-right" xs="6">
					                  <Button size="sm" className="btn-neutral btn-round btn-icon" color="default" onClick={() => this.newQuestion()} >
					                    <span className="btn-inner--icon mr-1"><i className="fas fa-plus"></i></span>
					                    <span className="btn-inner--text">Nova questão</span>
					                  </Button>
					                </Col>
	                			</Row>
	            			 	</CardHeader>
	            			 	<div> 
						  			{
						  				(this.state.questions.length === 0) &&
						  				<center style={{marginTop:'20px',marginBottom:'20px'}}>Nenhuma questão foi encontrada.</center>
						  			}
						  			<SortablePane dragHandleClassName="movedor" direction="vertical" margin={0} order={this.state.orderQuestion} onOrderChange={order => this.changeOrderQuestion(order)} >
						          	{this.state.listQuestion}
							        </SortablePane>
						  		</div>
            			 	</Card>
				  		</Col>
				  	</Row>
				  	</Col>
					<Modal className="modal-dialog-centered modal-danger" contentClassName="bg-gradient-danger" isOpen={this.state.openDialogRemoveQuestion} >
						<div className="modal-header">
							<h6 className="modal-title" id="modal-title-notification">Sua atenção é requirida.</h6>
							<button aria-label="Close" className="close" data-dismiss="modal" type="button" >
							  <span aria-hidden={true} onClick={ () => this.setState({openDialogRemoveQuestion:false}) }>×</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="py-3 text-center">
							  <i className="fas fa-exclamation-triangle fa-x3" style={{fontSize:'45px'}}></i>
							  <h4 className="heading mt-4">VOCÊ DEVE LER ISTO!</h4>
							  <p>Se você prosseguir, todos os dados da questão serão removidos, essa ação é irreversível. </p>
							</div>
						</div>
						<div className="modal-footer">
							<Button className="btn-white" color="default" type="button" onClick={ () => this.removeQuestionApi() }>Ok, Remova</Button>
							<Button className="text-white ml-auto" color="link" data-dismiss="modal" type="button" onClick={ () => this.setState({openDialogRemoveQuestion:false}) }>
							  Cancelar e fechar
							</Button>
						</div>
					</Modal>
		</React.Fragment>)
	}

}

export default MountQuiz;