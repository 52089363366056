import React from "react";
import {Card,CardHeader,CardBody,Container,Row,Col,Button,Input,Modal} from "reactstrap";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import api from "./../../Api.js";
import $ from 'jquery';
import { SortablePane, Pane } from 'react-sortable-pane';
import NotificationAlert from "react-notification-alert";
import ReactQuill from "react-quill";
import 'rc-time-picker/assets/index.css';
import InputMask from 'react-input-mask';

class NewLesson extends React.Component {

  	constructor(props){

  		super(props);
  		this.state = {
			 
			videos:[],
		 	openDialogRemoveLesson:false,
  		 	lessons:[],
			loadLesson:false,
  			loadCourse:false,
  			loadUnit:false,
  			loadQuiz:false,
  			quizzes:[],
  			editLesson:"",
  			dialogLesson:{
  				name:"",
  				icon:"0",
  				type:"1",
  				sequence:"",
  				iframeURL:"",
  				iframeAttributes:"",
  				html:"",
  				unit:"",
  				quiz:"",
  				video:"",
  				estimatedTime:"00:00:00",
  				lockTime:"00:00:00"
  			},

  			tags: [
                { id: "Thailand", text: "Thailand" },
                { id: "India", text: "India" }
             ],
            suggestions: [
                {id: 'USA', text: 'USA' },
                {id: 'Germany', text: 'Germany' },
                {id: 'Austria', text: 'Austria' },
                {id: 'Costa Rica', text: 'Costa Rica' },
                {id: 'Sri Lanka', text: 'Sri Lanka' },
                {id: 'Thailand', text: 'Thailand' }
            ]

  		};

  		this.saveLesson = this.saveLesson.bind(this);
  		this.postLesson = this.postLesson.bind(this);
  		this.putLesson = this.putLesson.bind(this);
 		this.removeLesson = this.removeLesson.bind(this);
 		this.goLesson = this.goLesson.bind(this);

        this.handleDelete = this.handleDelete.bind(this);
        this.handleAddition = this.handleAddition.bind(this);
        this.handleDrag = this.handleDrag.bind(this);
        this.resetDialogLesson = this.resetDialogLesson.bind(this);
        this.getCourseQuiz = this.getCourseQuiz.bind(this);

  		window.newLesson = this;

  	}


 	handleDelete(i) {
        const { tags } = this.state;
        this.setState({ tags: tags.filter((tag, index) => index !== i), });
    }
 
    handleAddition(tag) {
        this.setState(state => ({ tags: [...state.tags, tag] }));
    }
 
    handleDrag(tag, currPos, newPos) {
        const tags = [...this.state.tags];
        const newTags = tags.slice();
 
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
 
        // re-render
        this.setState({ tags: newTags });
    }

    resetDialogLesson(){

    	this.setState({dialogLesson:{
  				name:"",
  				icon:"0",
  				type:"1",
  				sequence:"",
  				iframeURL:"",
  				iframeAttributes:"",
  				html:"",
  				unit:"",
  				quiz:"",
  				video:"",
  				estimatedTime:"00:00:00",
  				lockTime:"00:00:00"
  			}});

    }

    //Para pegar os quizzes cadastrado no curso
	async getCourseQuiz(){

	    var course = await api.getQuizCourse(this.props.match.params.course).then((res) => {

	      this.setState({quizzes:res.data,loadQuizzes:true});
//	      console.log(res.data);
		  return true;
	    }).catch(error => {

	      console.log(error.data);
	      console.log(error);
	      console.log(error.response);
	      //if(error.response.status === 404){ this.props.history.push('/'); }
		  return false;
		});
		
		return course;

	}

	//Para pegar os vídeos cadastrados no curso
	async getCourseVideo(){

	    var videos = await api.getVideoCourse(this.props.match.params.course).then((res) => {

	      this.setState({videos:res.data,loadVideos:true});
	      console.log(res.data);
		  return true;

	    }).catch(error => {

	      console.log(error.data);
	      console.log(error);
	      console.log(error.response);
	      //if(error.response.status === 404){ this.props.history.push('/'); }
		  return false;
		  
	    });

		return videos;

	}

  	//Para pegar todas as lessons da unit
    async getLessons(){

		var unit = await api.getLessonUnit(this.props.match.params.unit).then((res) => {
			 
        	this.setState({lessons:res.data,loadLesson:true});
			return true;

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
        	console.log(error.response);
			return false;
  		});

		return unit;

	}
  
  	//Para pegar os dados do curso
  	async getCourse(){

  		await api.getCourse(this.props.match.params.course ).then((res) => {
            
        	this.setState({course:res.data,loadCourse:true});
        	console.log(res.data);

	  	}).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);

	    	if(error.response.status === 404){ this.props.history.push('/'); }

	  	});

	}

  	//Para pegar as units
  	async getUnit(){

		await api.getUnit(this.props.match.params.unit).then((res) => {
            
        	this.setState({unit:res.data,loadUnit:true});
        	console.log(res.data);
        	return true;

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
        	console.log(error.response);
        	return false;
  		});
 
	}
 
	//para cadastrar a lesson
	async postLesson(){

		var data = JSON.parse(JSON.stringify(this.state.dialogLesson));
		data.unit = this.props.match.params.unit;
		delete data.lessonGroup;
		
		if(data.videoHelper === null){

			data.videoHelper = "";

		}

		await api.postLesson(data).then((res) => {
            
            this.getLessons();
        	let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso</span><span data-notify="message">A lesson foi cadastrada.</span></div>),type: 'success',icon: "fas fa-check",autoDismiss: 7};
			this.refs.notificationAlert.notificationAlert(options);
			console.log(res.data);
			this.props.history.push('/course/'+this.state.course.id+'/unit/'+this.state.unit.id+'/lesson/'+res.data.lesson.id);

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
        	console.log(error.response);

        	if(error.response.status === 400){
   
          		let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Não foi possível cadastrar a lesson.</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
  				this.refs.notificationAlert.notificationAlert(options);

          	}

          	return false;

  		}); 

	}

	removeLesson(id){

		this.setState({removeLesson:id,openDialogRemoveLesson:true});

	}

	//Para remover a lesson no banco de dados
	async removeLessonApi(){

      this.setState({openDialogRemoveLesson:false});

      await api.deleteLesson(this.state.removeLesson).then(async (res) => {
            
        await this.getLessons();
        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso.</span><span data-notify="message">A unit foi removida.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
        this.refs.notificationAlert.notificationAlert(options);

        if(parseInt(this.state.removeLesson) === parseInt(this.state.dialogLesson.id)){

			//se tiver pelo menos um...
			if(this.state.lessons.length === 0){

				this.props.history.push('/course/'+this.props.match.params.course+'/unit/'+this.props.match.params.unit+'/lesson/new');
				this.resetDialogLesson();
				console.log('sem nada');
				
			}else{

				//course/'+this.props.match.params.course+'/unit/'+this.props.match.params.unit+'/lesson/'+this.props.match.params.lesson+'
				this.goLesson(this.state.lessons[0].id);
				console.log('mandou para a primeiura');

			}
			//se não tiver nenhum, mandar para criar um novo
			 

		}

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

      });

  }

	//Para atualizar a lesson
	async putLesson(){

		var data = JSON.parse(JSON.stringify(this.state.dialogLesson));
		delete data.unit;
		delete data.id;
		delete data.dataVideo;
		delete data.lessonGroup;

		if(data.videoHelper === null){

			data.videoHelper = "";

		}

		await api.putLesson(this.props.match.params.lesson,data).then(async (res) => {
            
            //Para pegar todas as lessons
            await this.getLessons();
        	let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso</span><span data-notify="message">As alterações foram salvas.</span></div>),type: 'success',icon: "fas fa-check",autoDismiss: 7};
			this.refs.notificationAlert.notificationAlert(options);


  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
        	console.log(error.response);

        	if(error.response.status === 400){
   
          		let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Não foi salvar as alterações.</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
  				this.refs.notificationAlert.notificationAlert(options);

          	}

          	return false;

  		}); 

	}

	//Para pegar a lesson caso esteja editando
	async getLesson(id){

		await api.getLesson(id).then((res) => {
            
         	var lesson = res.data;
         	delete lesson.createDate;
			delete lesson.updateDate;
			 
			if(res.data.lockTime === null || res.data.lockTime === ""){ res.data.lockTime = "00:00:00"; }
			if(res.data.estimatedTime === null || res.data.estimatedTime === ""){ res.data.estimatedTime = "00:00:00"; }

         	this.setState({dialogLesson:lesson});

	  	}).catch(error => {

	        console.log(error.data); 
	        console.log(error);
			console.log(error.response);
			if(error.response !== undefined){

				if(error.response.status === 404){

					this.props.history.push('/');

				}

			}

	  	});

	}

	//Caso clique o lapis para editar uma lesson na navegação rápida
	goLesson(id){

		this.props.history.push('/course/'+this.props.match.params.course+'/unit/'+this.props.match.params.unit+'/lesson/'+id);
		this.getLesson(id);

	}

	//Para saver se deve cadastrar ou atualizar
	saveLesson(event){

		event.preventDefault();
    	if(this.props.match.params.lesson === "new"){ this.postLesson(); }else{ this.putLesson(); }

	}

	async componentDidMount(){

		await this.getUnit();
		await this.getCourse();
		await this.getLessons();
		await this.getCourseQuiz();
		await this.getCourseVideo();

		//Caso esteja editando
		if(this.props.match.params.lesson === "new"){}else{

			await this.getLesson(this.props.match.params.lesson);

		}

		window.addEventListener("resize", this.updateDimensions);

	}

	componentWillMount(){
        this.updateDimensions();
	}

	componentWillUnmount(){

		window.removeEventListener("resize", this.updateDimensions);

	}
	
	//Para atualizar a ordem das lessons
	updateOrder(order){

		$(".__resizable_base__").remove();
		order.forEach(function(item,index,array){

	        api.changeOrderLesson(item,index).then((res) => {}).catch(error => {

	            console.log(error.data);
	            console.log(error);
	            console.log(error.response);
	            return false;

	        });

	    });
		$(".__resizable_base__").remove();
	}

	tselect(v){

		console.log(v);

	}
 

  	handleValueChange(value,name){

  		console.log(value && value.format('mm:ss'));
	 	//console.log(value);
	 	//console.log(name);

	 	var dialogLesson = this.state.dialogLesson;
        dialogLesson[name] = '00:'+value.format('mm:ss');
        this.setState({dialogLesson:dialogLesson});

  	}
  	
  	handleChange = name => event => {

	        var dialogLesson = this.state.dialogLesson;
			dialogLesson[name] = event.target.value;
			
			if(name === "type"){
				
				//texto
				if(event.target.value === "0" || event.target.value === "1"){ dialogLesson.icon = 0; }

				//quiz
				if(event.target.value === "3"){ dialogLesson.icon = 3; }

				//video
				if(event.target.value === "5"){ dialogLesson.icon = 1; }

			}

			console.log(event.target.value);

	        this.setState({dialogLesson:dialogLesson});
	};

	//Para setar a dimenção da navegação rápida
	updateDimensions(){
	
//		var w = $(window).width();
		var h = $(window).height();

		h = h - 66 - 78 - 79 - 10;
		$("#listLessons").css('height',h+'px');
		$("#listLessons").css('overflow-y','auto');
		$("#listLessons").css('overflow-x','hidden');
		$("#listLessons").css('padding','15px 0px 0px 20px');

	}

  	render(){
 
		var titlePage,titlePageUltimo,txtButton,buttonsSimpleHeader;

	    //caso esteja cadastrando
	    if(this.props.match.params.lesson === "new"){

	    	titlePage = "Nova aula";
	    	titlePageUltimo = "Nova aula";
	    	txtButton = "Cadastrar aula";
	    	buttonsSimpleHeader = <React.Fragment></React.Fragment>;

	    }else{

	    	titlePage = "Editar aula";
	    	titlePageUltimo = "Editar aula";
	    	txtButton = "Salvar alterações";
	    	buttonsSimpleHeader = <React.Fragment><Button onClick={ () => { this.props.history.push('/course/'+this.state.course.id+'/unit/'+this.state.unit.id+'/lesson/new'); this.resetDialogLesson(); } } color="secondary" size="sm" type="button"><i class="fas fa-plus"></i> Nova aula</Button></React.Fragment>;

	    }

//		var totHeight = this.state.lessons.length*60;
		var styleOne = {};
		var dfSize = {width:'100%',height:33};
		var margin = 20;

		this.updateDimensions();

		var panes = [];
		this.state.lessons.map((value,index) => { 

			if(value.icon_file !== undefined){ value.icon_file = sessionStorage.getItem('backend')+value.icon_file.url; }else{ value.icon_file = ''; }

			var is;

			if(this.state.dialogLesson.id === parseInt(value.id)){

				is = "editandoLesson";

			}else{

				is = "";

			}

			panes.push(
					<Pane resizable={{x:false,y:false,xy:false}} className="paneLessonMove" key={value.id} defaultSize={dfSize}  >
					<div className={'timeline-block '+is}>
				
							<i onClick={ () => this.removeLesson(value.id) }  style={{'zIndex':'9999',cursor:'pointer',float:'right',fontSize:'13px',marginTop:'9px',marginLeft:'5px',marginRight:'10px'}} className="far fa-trash-alt"></i>
							<i onClick={ () => this.goLesson(value.id) }  style={{'zIndex':'9999',cursor:'pointer',float:'right',fontSize:'13px',marginTop:'9px',marginLeft:'5px',marginRight:'0px'}} className="fas fa-pencil-alt"></i>
							
							<span onClick={ () => this.goLesson(value.id) } className="timeline-step badge-info" style={{cursor:"pointer",left:'10px'}}><i className={window.iconsLesson[value.icon]} />   </span>
							<i className="fas fa-arrows-alt movedor" style={{position:'absolute',top:'11px',left:'39px',fontSize:"12px"}}></i>
							<div onClick={ () => this.goLesson(value.id) } className="timeline-content" style={{width:'fit-content',zIndex:'0'}}>
							<input className="input_edit_lesson" readOnly value={value.name} />
							</div>
					</div>
				</Pane>);

				return true;

			})
		 
			window.$('.__resizable_base__').remove();

	    //caso esteja editnado
  		return(
  			<React.Fragment>
  			<div>
                </div>

  					<div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
  					{
  						(this.state.loadCourse === true && this.state.loadUnit === true) &&
						<SimpleHeader name={titlePage} way={[
							{name:"Cursos",url:"/course"},
							{name:this.state.course.name,url:'/course/'+this.state.course.id},
							{name:this.state.unit.name,url:'/course/'+this.state.course.id+'/unit/'+this.state.unit.id+'/mountlesson'},
							{name:titlePageUltimo,url:this.props.history.location.pathname}]} buttons={buttonsSimpleHeader} />

  					}
  					<Container className="mt--6" fluid>
			      		<Row>
				    		<Col lg="9">
					          	<Card>
					                <CardHeader className="bg-transparent">
					                <Row><Col xs="3"><h3 className="mb-0">{titlePage}</h3></Col></Row>
					                </CardHeader>
					                <CardBody className="bg-secondary">
					                	<Row>
					                		<Col md="4">
												<div><h5>Nome da lesson:</h5><Input onChange={this.handleChange('name')} value={ this.state.dialogLesson.name } className="form-control-alternative" type="text" /></div>
					                		</Col>
					                		<Col md="2">
					                			<div><h5>Tmp. de bloqueio:</h5>
												 
					  								<InputMask className="form-control form-control-alternative"  onChange={ this.handleChange('lockTime') } value={ this.state.dialogLesson.lockTime } mask="99:99:99" maskChar=" " onFocus={() => this.setState({ focusedNascimento: true }) } onBlur={() => this.setState({ focusedNascimento: false }) }/>
											  
											</div>
					                		</Col>
					                		<Col md="2">
					                			<div><h5>Tempo estimado:</h5>
													<InputMask className="form-control form-control-alternative" onChange={ this.handleChange('estimatedTime') } value={ this.state.dialogLesson.estimatedTime } mask="99:99:99" maskChar=" " onFocus={() => this.setState({ focusedNascimento: true }) } onBlur={() => this.setState({ focusedNascimento: false }) }/>
                         						</div>
					                		</Col>
					                		<Col md="2">
												<div><h5>Ícone:</h5>
													<Input onChange={this.handleChange('icon')} value={ this.state.dialogLesson.icon } className="form-control-alternative" type="select">
														<option value="0">Texto</option>
														<option value="1">Vídeo</option>
														<option value="2">Audio</option>
														<option value="3">Quiz</option>
														<option value="4">Gamepad</option>
													</Input>
												</div>
					                		</Col>
					                		<Col md="2">
												<div>
													<h5>Tipo:</h5>
													<Input onChange={this.handleChange('type')} value={ this.state.dialogLesson.type } className="form-control-alternative" type="select">
														<option selected disabled value="disabled"></option>
														<option value="0">Texto livre com editor</option>
														<option value="1">Texto livre sem editor</option>
														<option value="2">Iframe</option>
														<option value="3">Quiz</option>
														<option value="5">Vídeo</option>
            										</Input>
												</div>
					                		</Col>
											
					                	</Row>

										<Row style={{marginTop:'10px'}}>
											<Col md="2">
												<div>
													<h5>Vídeo de ajuda:</h5>
													<Input onChange={this.handleChange('videoHelper')} value={ this.state.dialogLesson.videoHelper } className="form-control-alternative" type="select">
														<option value=""></option>
														{

															this.state.videos.map((value,index) => {

																return(<option value={value.id}>{value.name}</option>)

															})

														} 
            										</Input>
												</div>
					                		</Col>
										</Row>
					                	<Row>
					                		<Col md="6">
					                			<br/>
					                			<h5>Descrição:</h5>
					                			<Input onChange={this.handleChange('description')} value={ this.state.dialogLesson.description } style={{minHeight:'100px'}} className="form-control-alternative" type="textarea"></Input>
					                		</Col>
					                		<Col md="6" hidden>
					                			<br/>
					                			<h5>Competencias:</h5>
						                		<Row>
										        </Row>
					                		</Col>
					                	</Row>
					                	<Row style={{marginTop:'10px'}}>
					                		{	
					                			//Texto com editor
								        		(parseInt(this.state.dialogLesson.type) === 0) &&
								        		<React.Fragment>

												 
									        		<form onSubmit={ (event) => this.saveLesson(event) } style={{width:'100%'}}>
									        		<Col md="12" lg={12}> 
										        		
										        		<h5>Texto:</h5>
														<ReactQuill onChange={ (a) => { var dialogLesson = this.state.dialogLesson; dialogLesson.html = a; this.setState({dialogLesson:dialogLesson}) } } value={ this.state.dialogLesson.html } style={{width:"100%"}} theme="snow" 
														
															modules={{toolbar: [
																[{font:[]},{size:[]}],
																['bold','italic','underline','strike'],
																[{color:[]},{'background':[]}],
																[{script:'super'},{'script':'sub'}],
																[{header:'1'},{header:'2'},'blockquote','code-block'],
																[{list:'ordered'},{list:'bullet'},{indent:'-1'},{indent:'+1'}],
																['direction',{align:[]}],
																['link','image','video','formula'],
																['clean']
															] 
														
														}} 
														 
														/>
									        			<Button color="primary" type="submit" style={{float:'right',marginTop:"10px"}}>{txtButton}</Button>

													</Col>
									        		</form>

								        		</React.Fragment>
								        	}
								        	{	//Texto sem editore
								        		(parseInt(this.state.dialogLesson.type) === 1) &&
								        		<React.Fragment>
									        		<Col md="12">
									        			<form onSubmit={ (event) => this.saveLesson(event) } >
										        			<h5>Texto:</h5>
										        			<Input onChange={this.handleChange('html')} value={ this.state.dialogLesson.html } className="form-control-alternative" type="textarea"></Input>
															<br/>
															<Button color="primary" type="submit" style={{float:'right'}}>{txtButton}</Button>
														</form>
													</Col>
								        		</React.Fragment>
								        	}
								        	{	
								        		//Iframe
								        		(parseInt(this.state.dialogLesson.type) === 2) &&
								        		<React.Fragment>

								        			<Container className="" style={{marginTop:'25px'}}>
														<form onSubmit={ (event) => this.saveLesson(event) } >
															<center><h3>Configuração do iframe</h3></center>
															<row>
																
																Você pode passar as variaveis abaixo caso precise fazer alguma integração.<br/>

																<br/><br/>Variáveis da licença:<br/>

																<strong>[[url_engine_backend]] </strong>
																<strong>[[url_engine_frontend]] </strong>
																<strong>[[url_front_reactengine]] </strong>
																<strong>[[url_back_reactengine]] </strong>
																<strong>[[course_id]] </strong>
																<strong>[[license]] </strong>
																<strong>[[url_scratch]] </strong>

																<strong>[[url_back_ide_html]] </strong>
																<strong>[[url_front_ide_html]] </strong>

																<br/><br/>Variáveis do usuário:<br/>
																<strong>[[id_user]] </strong>
																<strong>[[user_name]] </strong>
																<strong>[[user_lastName]] </strong>
																<strong>[[user_username]] </strong>
																<strong>[[user_birthDate_type_datedb]] </strong>
																<strong>[[user_birthDate_type_default]] </strong>

																<br/><br/>Variáveis do curso:<br/>
																<strong>[[course_id]] </strong>
																<strong>[[course_name]] </strong>
																<strong>[[course_description]] </strong>
 
																<br/><br/>
																Variáveis da turma:
																<br/>
																<strong>[[id_classRoom]] </strong>
																<strong>[[classRoom_name]] </strong>
																 

																<br/><br/>
																Variáveis do professor da turma:
																<br/>
																<strong>[[teacher_name]] </strong>
																<strong>[[teacher_lastName]] </strong>
																<strong>[[teacher_email]] </strong>
																<strong>[[teacher_username]] </strong>



																

																

															</row>
															<Row>
																<Col md="6">
																	<br/>
																	<div>
																		<h5>Informe a URL (src):</h5>
																		<Input onChange={this.handleChange('iframeURL')} value={ this.state.dialogLesson.iframeURL } className="form-control-alternative" type="text" />
																	</div>
																</Col>
														
																<Col md="6">
																	<br/>
																	<div>
																		<h5>Atributos:</h5>
																		<Input onChange={this.handleChange('iframeAttributes')} value={ this.state.dialogLesson.iframeAttributes } className="form-control-alternative" type="text" />
																	</div>
																	<p className="h6">Ex: class="frameEx" style="" </p>
																</Col>
															</Row>
														
															<Row>
																<Col md="12">
																	<br/>
																	<Button color="primary" type="submit" style={{width:'100%'}}>{txtButton}</Button>
																</Col>
															</Row>
														</form>  
													</Container>
								        		</React.Fragment>
								        	}{	
								        		//Quiz
								        		(parseInt(this.state.dialogLesson.type) === 3) &&
								        		<React.Fragment>
								        			<Container className="" style={{marginTop:'25px'}}>
														<Row className="justify-content-center">
															<Col lg="5" md="7">
														  		<Card className="border-0 mb-0">
														    		<CardBody>
														    			<form onSubmit={ (event) => this.saveLesson(event) } >
															    			<center><h3>Configuração do quiz</h3></center>
															    			<Row>
																    			<Col md="12">
																	    			<br/>
															    			     	<div>
															    			     		<h5>Escolha um quiz:</h5>
															    			     		<Input onChange={this.handleChange('quiz')} value={ this.state.dialogLesson.quiz } className="form-control-alternative" type="select" >
																						 	<option value=""></option> 
																							{

															    			     				this.state.quizzes.map((value,index) => {

															    			     					return(<option value={value.id}>{value.name}</option>)

															    			     				})

															    			     			}
															    			     		</Input>
															    			     	</div>
														    			     	</Col>
													    			     	</Row>
													    			     	<Row>
													    			     		<Col md="12">
												    			     			<br/>
												    			     			<Button color="primary" type="submit" style={{width:'100%'}}>{txtButton}</Button>
												    			     			</Col>	 
													    			     	</Row>
												    			     	</form>
														    		</CardBody>
														    	</Card>
														   	</Col>
														</Row>
													</Container>
								        		</React.Fragment>
								        	}
								        	{	
								        		//Quiz
								        		(parseInt(this.state.dialogLesson.type) === 5) &&
								        		<React.Fragment>
								        			<Container className="" style={{marginTop:'25px'}}>
														<Row className="justify-content-center">
															<Col lg="5" md="7">
														  		<Card className="border-0 mb-0">
														    		<CardBody>
														    			<form onSubmit={ (event) => this.saveLesson(event) } >
															    			<center><h3>Configuração do vídeo</h3></center>
															    			<Row>
																    			<Col md="12">
																	    			<br/>
															    			     	<div>
															    			     		<h5>Escolha um vídeo:</h5>
															    			     		<Input onChange={this.handleChange('video')} value={ this.state.dialogLesson.video } className="form-control-alternative" type="select" >
																							<option value=""></option>  
																							{

															    			     				this.state.videos.map((value,index) => {

															    			     					return(<option value={value.id}>{value.name}</option>)

															    			     				})

															    			     			}
															    			     		</Input>
															    			     	</div>
														    			     	</Col>
													    			     	</Row>
													    			     	<Row>
													    			     		<Col md="12">
												    			     			<br/>
												    			     			<Button color="primary" type="submit" style={{width:'100%'}}>{txtButton}</Button>
												    			     			</Col>	 
													    			     	</Row>
												    			     	</form>
														    		</CardBody>
														    	</Card>
														   	</Col>
														</Row>
													</Container>
								        		</React.Fragment>
								        	}
								        	{	
								        		//Questão discursiva
								        		(parseInt(this.state.dialogLesson.type) === 4) &&
								        		<React.Fragment>
								        		</React.Fragment>
								        	}
					                	</Row>
					                </CardBody>
					            </Card>
				            </Col>
				            <Col lg="3">
								<Card>
		                		    <CardHeader className="bg-transparent">
					                	<Row>
						                	<Col md="12"><h3 className="mb-0">Navegação rápida</h3></Col>
					                    </Row>
					                </CardHeader>
					                <CardBody className="bg-secondary" id="listLessons" style={styleOne} >
					                	{
					                		(this.state.loadLesson === true) &&
					                		<React.Fragment>
					                			{
					                				(this.state.lessons.length === 0) &&
					                				<center><h5>Nenhuma lesson foi encontrada.</h5></center>
					                			}	
					                		</React.Fragment>
					                	}{
				                  			(this.state.loadLesson === true) &&
			                    		 	<SortablePane direction="vertical" margin={margin} dragHandleClassName="movedor" onOrderChange={order => { this.updateOrder(order) }}>
							                    { panes }
						                    </SortablePane>
					                    }
					                </CardBody>
					            </Card>
				            </Col>
				        </Row>
				        <Row>
				    </Row>
				    </Container>
				    <Modal className="modal-dialog-centered modal-danger" contentClassName="bg-gradient-danger" isOpen={this.state.openDialogRemoveLesson} >
			          <div className="modal-header">
			            <h6 className="modal-title" id="modal-title-notification">Sua atenção é requirida.</h6>
			            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.toggleModal("notificationModal")}>
			              <span aria-hidden={true} onClick={ () => this.setState({openDialogRemoveUser:false}) }>×</span>
			            </button>
			          </div>
			          <div className="modal-body">
			            <div className="py-3 text-center">
			              <i className="fas fa-exclamation-triangle fa-x3" style={{fontSize:'45px'}}></i>
			              <h4 className="heading mt-4">VOCÊ DEVE LER ISTO!</h4>
			              <p>Se você prosseguir, todos os dados da lesson serão removidos, essa ação é irreversível.</p>
			            </div>
			          </div>
			          <div className="modal-footer">
			            <Button className="btn-white" color="default" type="button" onClick={ () => this.removeLessonApi() }>Ok, Remova</Button>
			            <Button className="text-white ml-auto" color="link" data-dismiss="modal" type="button" onClick={ () => this.setState({openDialogRemoveLesson:false}) }>
			              Cancelar e fechar
			            </Button>
			          </div>
			        </Modal>
  			</React.Fragment>
  		)
  	}
}
export default NewLesson;