import React from "react";
import api from "./../../Api.js";
import moment from "moment";
import classnames from "classnames";
import {Alert,Modal,CardBody,FormGroup,InputGroupAddon,Form,Input,InputGroupText,InputGroup,Button,Card,CardHeader,Table,Container,Row,Col,UncontrolledTooltip} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import InputMask from 'react-input-mask';
import CurrencyInput from 'react-currency-input';
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import CourseLicenseAvailable from "./modal/CourseLicenseAvailable.jsx";
import ModalSchoolDatas from "./modal/ModalSchoolDatas.jsx";

class Tables extends React.Component {

  constructor(props){

      super(props);

      this.removerLicense = this.removerLicense.bind(this);
      this.saveLicense = this.saveLicense.bind(this);
      this.editLicense = this.editLicense.bind(this);
      this.listURL = this.listURL.bind(this);
      this.putLicense = this.putLicense.bind(this);
      this.newLicense = this.newLicense.bind(this);
      this.postLicense = this.postLicense.bind(this);
      this.removeLicenseApi = this.removeLicenseApi.bind(this);
      this.editCourseLicense = this.editCourseLicense.bind(this);

      this.state = {loadComplete:false,openDialogLicenseCreate:false,license:[],openDialogLicenseInfosModal:false,

        dialog:{name:"",license:""}

      };
   
      window.license = this;

  }

  //para buscar todos os usuarios via api
  async getAllLicense(){

      await api.getAllLicense().then((res) => {

        this.setState({license:res.data});

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

      });
    
  }

  //par apegar todos os levels de acesso
  async getAllLevelAcess(){

      await api.levelAcess().then((res) => {
        
        this.setState({levelAcess:res.data});

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

      });

  }
 

  //para atualizar os dados do usuario no sistema.
  async putLicense(){

      var data = JSON.parse(JSON.stringify(this.state.dialog));
      data.expirationDate = moment(data.expirationDate,'DD/MM/YYYY').format('YYYY-MM-DD')

      console.log(data);

      await api.putLicense(this.state.editLicense,data).then((res) => {
          
        this.setState({openDialogLicenseModal:false});
        
        this.setState({});
        
        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">A licença foi atualizada com sucesso.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
        this.refs.notificationAlert.notificationAlert(options);
  
        var state = this.state.license;

        var index  = state.map(function(x) {return x.id; }).indexOf(this.state.editLicense);
        state[index] = res.data.license;
        this.setState({license:state});

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

        this.setState({loading:false});

          if(error.response.status === 400){
          this.setState({userError:""});
          this.setState({userError:error.response.data.msg});

        }

      });

  }

  //para cadastrar o usuario no banco de dados
  async postLicense(){

      var data = JSON.parse(JSON.stringify(this.state.dialog));
      data.expirationDate = moment(data.expirationDate,'DD/MM/YYYY').format('YYYY-MM-DD');

      await api.postLicense(data).then((res) => {

        this.setState({openDialogLicenseModal:false,loading:false});
        this.getAllLicense();

        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">A licença foi cadastrada.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
        this.refs.notificationAlert.notificationAlert(options);

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

        this.setState({loading:false});

         if(error.response.status === 400){

          this.setState({userError:""});
          this.setState({userError:error.response.data.msg});

        }

      });

  }

  //para editar os dados do usuario
  editLicense(id){
      
      this.setState({editLicense:id,openDialogLicenseModal:true,textDialogButton:"Salvar alterações",textDialog:"Editar licença"});
      var schools = this.state.license;

      for(let i = 0; i < schools.length; i++){
    
        if(parseInt(schools[i].id) === parseInt(id)){
          
          this.setState({
            dialog:{
            
              name:schools[i].name,
              license:schools[i].license,
              serverIP:schools[i].serverIP,
              url:schools[i].url,
              expirationDate: moment(schools[i].expirationDate,'YYYY-MM-DD').format('DD/MM/YYYY'),
              status:schools[i].status,
              userLimit:schools[i].userLimit,
              currencyType:schools[i].currencyType,

              contractValue: schools[i].contractValue.toFixed(2).replace(".", ","),

              url_front_school:schools[i].url_front_school,
              url_back_school:schools[i].url_back_school,
              url_front_engine:schools[i].url_front_engine,
              url_back_engine:schools[i].url_back_engine,

              url_front_reactengine:schools[i].url_front_reactengine,
              url_back_reactengine:schools[i].url_back_reactengine,
              url_scratch:schools[i].url_scratch,
              url_back_ide_html:schools[i].url_back_ide_html,
              url_front_ide_html:schools[i].url_front_ide_html,
 
            }});
         
        }

      } 
       

  }

  //Para cadastrar um usuário novo
  newLicense(){

    this.setState({editLicense:"",openDialogLicenseModal:true,textDialogButton:"Nova licença",textDialog:"Cadastrar"});
    this.setState({dialog:{

      url_front_school:"",
      url_back_school:"",
      url_front_engine:"",
      url_back_engine:"",
      
      license:"",serverIP:"",expirationDate:"",name:"",status:"1",url:"",userLimit:"",currencyType:"BRL"}});

  }


  //quando clicar no botão
  saveLicense(e){

      e.preventDefault();
  
      if(this.state.editLicense === ""){ this.postLicense(); }else{ this.putLicense(); }

  }
 

  //para remover um usuario do sistema
  removerLicense(id){

    this.setState({openDialogRemoveLicense:true,licenseDeletion:id});

  }

  //Para abrir o modal e listar a URLS dessa escola
  listURL(id){

    this.setState({openModalListUrl:true});
    var schools = this.state.license;

    for(let i = 0; i < schools.length; i++){
    
      if(parseInt(schools[i].id) === parseInt(id)){

        var modalListUrl = {};
        modalListUrl.url_front_school = schools[i].url_front_school;
        modalListUrl.url_back_school = schools[i].url_back_school;
        modalListUrl.url_front_engine = schools[i].url_front_engine;
        modalListUrl.url_back_engine = schools[i].url_back_engine;

        this.setState({modalListUrl:modalListUrl});

      }

    }

  }

  //para enviar os dados do usuario para atualização
  async removeLicenseApi(){

      this.setState({openDialogRemoveUser:false});
      await api.deleteUser(this.state.userDeletion).then((res) => {
          
        this.getAllLicense();
        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">O usuário foi removido.</span></div>),type: 'success',icon: "fas fa-exclamation",autoDismiss: 7};
        this.refs.notificationAlert.notificationAlert(options);

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

        this.setState({loading:false});

        if(error.response.status === 400){

          let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Não foi possível remover o usuário.</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
          this.refs.notificationAlert.notificationAlert(options);

        }

      });

  }


  editCourseLicense(id){

    this.setState({openModaCourseLicense:true,courseLicense:id});

  }

  //para criar um novo usuario no sistem,
  async componentDidMount(){
  
    await this.getAllLicense();

  }

  selectLanguage(id){

    var dialog = this.state.dialog;
    dialog.language = id;
    this.setState({openLanguageDialog:false,dialog:dialog});

  }
 
  render(){

    const handleChange = name => event => {
        var dialog = this.state.dialog;
        dialog[name] = event.target.value;
        this.setState({dialog:dialog});
    }

    
    var buttons = <React.Fragment></React.Fragment>;
 
    return(
      <React.Fragment>
        <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
        <SimpleHeader name="Licenças" way={[{name:"Licenças",url:"/license"}]} buttons={buttons} />
        <Container className="mt--6" fluid>
          <Card>
            <CardHeader className="border-0">
              <Row>
                <Col xs="6"><h3 className="mb-0">Lista de licença</h3></Col>
                <Col className="text-right" xs="6">
                  <Button className="btn-neutral btn-round btn-icon" color="default" href="#pablo" id="tooltip969372949" onClick={() => this.newLicense()} size="sm">
                    <span className="btn-inner--icon mr-1"><i class="fas fa-key"></i></span>
                    <span className="btn-inner--text">Nova Licença</span>
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th></th>
                  <th>Licença</th>
                  <th><center>Status</center></th>
                  <th>Server IP</th>
                  <th>Data de castro</th>
                  <th>Validade</th>
                  <th><center>Domínios</center></th>
                  <th><center>Usuários</center></th>
                  <th><center>Valor do contrato</center></th>
                  <th><center>Ações</center></th>
                </tr>
              </thead>
              <tbody>
              {

                this.state.license.map((value,index) => {

                  var key = "user_key_"+value.id;
                  var tooltip = "tooltip_user_key_"+value.id;
                  var tooltip2 = "tooltip2_user_key_"+value.id;
                  var simbol,status;
                  
                  if(parseInt(value.status) === 0){ status = <strong>Suspensa</strong>; }
                  if(parseInt(value.status) === 1){ status = <strong style={{color:"green"}}>Ativa</strong>; }

                  if(value.currencyType === "BRL"){ simbol = "R$"; }
                  if(value.currencyType === "USD"){ simbol = "$"; }
                  
                  return(<tr key={key}>
                      <td className="table-user"><img hidden alt="..."className="avatar rounded-circle mr-3"src={''}/><b>{value.name}</b></td>
                      <td> <input style={{border:"none"}} value={value.license} readOnly /> </td>
                      <td><center>{status}</center></td>
                      <td>{value.serverIP}</td>
                      <td>{moment(value.createDate,'YYYY-MM-DD').format('DD/MM/YYYY')}</td>
                      <td>{moment(value.expirationDate,'YYYY-MM-DD').format('DD/MM/YYYY')}</td>
                      <td> 
                        <center>

                          <a rel="noopener noreferrer" href={value.url_front_school} target="_blank" style={{cursor:'pointer'}} onClick={ () => this.listURL(value.id)} className="table-action" id={tooltip+'AAEE'} ><i class="fas fa-globe"/></a>
                          <UncontrolledTooltip delay={0} target={tooltip+'AAEE'}>Frontend</UncontrolledTooltip>

                          <a rel="noopener noreferrer" href={value.url_back_school} target="_blank" style={{cursor:'pointer'}} onClick={ () => this.listURL(value.id)} className="table-action" id={tooltip+'AAEA'} ><i class="fas fa-globe"/></a>
                          <UncontrolledTooltip delay={0} target={tooltip+'AAEA'}>Backend</UncontrolledTooltip>
                          
                          <a rel="noopener noreferrer" href={value.url_front_engine} target="_blank" style={{cursor:'pointer'}} onClick={ () => this.listURL(value.id)} className="table-action" id={tooltip+'AAEB'} ><i class="fas fa-globe"/></a>
                          <UncontrolledTooltip delay={0} target={tooltip+'AAEB'}>Frontend engine</UncontrolledTooltip>
                          
                          <a rel="noopener noreferrer" href={value.url_back_engine} target="_blank" style={{cursor:'pointer'}} onClick={ () => this.listURL(value.id)} className="table-action" id={tooltip+'AAEC'} ><i class="fas fa-globe"/></a>
                          <UncontrolledTooltip delay={0} target={tooltip+'AAEC'}>Backend engine</UncontrolledTooltip>
                        
                        </center>
                      </td>
                      <td><center>{value.userLimit}</center></td>
                      <td><center>{simbol} {value.contractValue.toFixed(2)}</center></td>
                      <td className="table-actions">
                        
                        <center>

                          <font style={{cursor:'pointer'}} onClick={ () => this.setState({openDialogLicenseInfosModal:true})} className="table-action" id={tooltip+"tedd"} ><i class="fas fa-info"></i></font>
                          <UncontrolledTooltip delay={0} target={tooltip+"tedd"}></UncontrolledTooltip>

                          <font style={{cursor:'pointer'}} onClick={ () => this.editLicense(value.id)} className="table-action" id={tooltip} ><i className="fas fa-pencil-alt"/></font>
                          <UncontrolledTooltip delay={0} target={tooltip}>Editar licença</UncontrolledTooltip>

                          <font style={{cursor:'pointer'}} onClick={ () => this.editCourseLicense(value.id)} className="table-action" id={tooltip+"a"} ><i className="fas fa-graduation-cap"></i></font>
                          <UncontrolledTooltip delay={0} target={tooltip+"a"}>Cursos disponíveis</UncontrolledTooltip>
                          
                          <font style={{cursor:'pointer'}} onClick={ () => this.removerLicense(value.id)} className="table-action table-action-delete" id={tooltip2} ><i className="fas fa-trash" /></font>
                          <UncontrolledTooltip delay={0} target={tooltip2}>Deletar licença</UncontrolledTooltip>

                        </center>

                      </td>
                    </tr>)

                  })}

              </tbody>
            </Table>
          </Card>
        </Container>

        <Modal className="modal-dialog-centered" isOpen={this.state.openDialogLicenseModal} >
          <div className="modal-body p-0">
            <Card className="bg-secondary border-0 mb-0">
            <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">{this.state.textDialog}</h6>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.setState({openDialogLicenseModal:false})}><span aria-hidden={true}>×</span> </button>
           </div>
              <CardBody className="px-lg-5">
                <Form role="form" type="post" onSubmit={ (event) => this.saveLicense(event) }>
                  {
                    (this.state.userError) &&
                    <Alert color="danger" className="animated tada"><strong>Ops!</strong> {this.state.userError}</Alert>
                  }

                  <Row>

                      <Col md={12}>
                        <h5>Nome</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedName})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('name')} value={ this.state.dialog.name } onFocus={() => this.setState({ focusedName: true }) } onBlur={() => this.setState({ focusedName: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <h5>Licença</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedLicense})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="fas fa-key" /></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('license')} value={ this.state.dialog.license } onFocus={() => this.setState({ focusedLicense: true }) } onBlur={() => this.setState({ focusedLicense: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <h5>Server IP</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedIP})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-world-2" /></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('serverIP')} value={ this.state.dialog.serverIP } onFocus={() => this.setState({ focusedIP: true }) } onBlur={() => this.setState({ focusedIP: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <h5>URL front school</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedurl_front_school})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i class="fas fa-link"></i></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('url_front_school')} value={ this.state.dialog.url_front_school } onFocus={() => this.setState({ focusedurl_front_school: true }) } onBlur={() => this.setState({ focusedurl_front_school: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <h5>URL back school</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.url_back_school})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i class="fas fa-link"></i></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('url_back_school')} value={ this.state.dialog.url_back_school } onFocus={() => this.setState({ focusedurl_back_school: true }) } onBlur={() => this.setState({ focusedurl_back_school: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <h5>URL front engine</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedurl_front_engine})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i class="fas fa-link"></i></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('url_front_engine')} value={ this.state.dialog.url_front_engine } onFocus={() => this.setState({ focusedurl_front_engine: true }) } onBlur={() => this.setState({ focusedurl_front_engine: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <h5>URL back engine</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.url_back_engine})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i class="fas fa-link"></i></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('url_back_engine')} value={ this.state.dialog.url_back_engine } onFocus={() => this.setState({ focusedurl_back_engine: true }) } onBlur={() => this.setState({ focusedurl_back_engine: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>




                      <Col md={12}>
                        <h5>URL front react engine</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedurl_front_engine2})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i class="fas fa-link"></i></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('url_front_reactengine')} value={ this.state.dialog.url_front_reactengine } onFocus={() => this.setState({ focusedurl_front_engine2: true }) } onBlur={() => this.setState({ focusedurl_front_engine2: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <h5>URL back react engine</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.url_back_engine2})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i class="fas fa-link"></i></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('url_back_reactengine')} value={ this.state.dialog.url_back_reactengine } onFocus={() => this.setState({ focusedurl_back_engine2: true }) } onBlur={() => this.setState({ focusedurl_back_engine2: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <h5>URL scratch</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.url_back_engine2url_scratch})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i class="fas fa-link"></i></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('url_scratch')} value={ this.state.dialog.url_scratch } onFocus={() => this.setState({ focusedurl_back_engine2url_scratch: true }) } onBlur={() => this.setState({ focusedurl_back_engine2url_scratch: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>
 
                      <Col md={12}>
                        <h5>URL back ide html</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.url_back_ide_html})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i class="fas fa-link"></i></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('url_back_ide_html')} value={ this.state.dialog.url_back_ide_html } onFocus={() => this.setState({ url_back_ide_html: true }) } onBlur={() => this.setState({ url_back_ide_html: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <h5>URL fronte ide html</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.url_front_ide_html})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i class="fas fa-link"></i></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('url_front_ide_html')} value={ this.state.dialog.url_front_ide_html } onFocus={() => this.setState({ url_front_ide_html: true }) } onBlur={() => this.setState({ url_front_ide_html: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={4}>
                        <h5>Status</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedStatus})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText>
                            {
                              (parseInt(this.state.dialog.status) === 0) &&
                              <i class="fas fa-toggle-off"></i>
                            }
                            {
                              (parseInt(this.state.dialog.status) === 1) &&
                              <i class="fas fa-toggle-on"></i>
                            }
                            </InputGroupText></InputGroupAddon>
                            <Input type="select" onChange={handleChange('status')} value={ this.state.dialog.status } onFocus={() => this.setState({ focusedStatus: true }) } onBlur={() => this.setState({ focusedStatus: false }) }>

                              <option value="1">Ativa</option>
                              <option value="0">Suspensa</option>

                            </Input>
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={4}>
                        <h5>LMT. Usuários</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedLMT})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i class="fas fa-users"></i></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('userLimit')} value={ this.state.dialog.userLimit } onFocus={() => this.setState({ focusedLMT: true }) } onBlur={() => this.setState({ focusedLMT: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={4}>
                        <h5>Validade</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedValidade})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputMask onChange={handleChange('expirationDate')} value={ this.state.dialog.expirationDate } className="form-control" mask="99/99/9999" maskChar=" " onFocus={() => this.setState({ focusedValidade: true }) } onBlur={() => this.setState({ focusedValidade: false }) }/>
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <h5>Valor do contrato</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedValidade})} >
                          <InputGroup className="input-group-merge input-group-alternative"> 
                            { (this.state.dialog.currencyType === "USD") &&
                              <CurrencyInput decimalSeparator="," thousandSeparator="" className="form-control" prefix="$ " value={this.state.dialog.contractValue} onChangeEvent={handleChange('contractValue')}/>
                            }
                            { (this.state.dialog.currencyType === "BRL") &&
                              <CurrencyInput decimalSeparator="," thousandSeparator="" className="form-control" prefix="R$ " value={this.state.dialog.contractValue} onChangeEvent={handleChange('contractValue')}/>
                            }
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <h5>Moeda</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedCurrencyType})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <Input type="select" onChange={handleChange('currencyType')} value={ this.state.dialog.currencyType } className="form-control" onFocus={() => this.setState({ focusedCurrencyType: true }) } onBlur={() => this.setState({ focusedCurrencyType: false }) }>
                              <option value="BRL">BRL</option>
                              <option value="USD">USD</option>
                            </Input>
                          </InputGroup>
                        </FormGroup>
                      </Col>

                  </Row>
      
                  <div className="text-center">
                    <Button className="my-4" color="default" type="button" onClick={ () => this.setState({openDialogLicenseModal:false}) } >Cancelar</Button>
                    <Button className="my-4" color="primary" type="submit">{this.state.textDialogButton}</Button>
                  </div>

                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
        
        <Modal className="modal-dialog-centered modal-danger" contentClassName="bg-gradient-danger" isOpen={this.state.openDialogRemoveUser} >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">Sua atenção é requirida.</h6>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.toggleModal("notificationModal")}>
              <span aria-hidden={true} onClick={ () => this.setState({openDialogRemoveUser:false}) }>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <i class="fas fa-exclamation-triangle fa-x3" style={{fontSize:'45px'}}></i>
              <h4 className="heading mt-4">VOCÊ DEVE LER ISTO!</h4>
              <p>Se você prosseguir, todos os dados do usuário serão removidos, essa ação é irreversível. É recomendavel <strong style={{fontWeight:'700'}}>bloquear</strong> o usuário ao invés de remove-lo. </p>
            </div>
          </div>
          <div className="modal-footer">
            <Button className="btn-white" color="default" type="button" onClick={ () => this.removeUserApi() }>Ok, Remova</Button>
            <Button className="text-white ml-auto" color="link" data-dismiss="modal" type="button" onClick={ () => this.setState({openDialogRemoveUser:false}) }>
              Cancelar e fechar
            </Button>
          </div>
        </Modal>

        <ModalSchoolDatas state={this.state} setState={ (data) => this.setState(data) } />

        <CourseLicenseAvailable setState={ (data) => this.setState(data) } state={this.state} />

      </React.Fragment>

    );
  }
}
export default Tables;