import React from "react";
import api from "./../../Api.js";
import moment from "moment";
import classnames from "classnames";
import $ from 'jquery';
import {Alert,Modal,CardBody,FormGroup,InputGroupAddon,Form,Input,InputGroupText,InputGroup,Button,Card,CardHeader,Table,Container,Row,Col,UncontrolledTooltip} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import InputMask from 'react-input-mask';
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";

class Tables extends React.Component {

  constructor(props){

      super(props);
      this.removerUser = this.removerUser.bind(this);
      this.saveUser = this.saveUser.bind(this);
      this.editUser = this.editUser.bind(this);
      this.putUser = this.putUser.bind(this);
      this.newUser = this.newUser.bind(this);
      this.postUser = this.postUser.bind(this);
      this.removeUserApi = this.removeUserApi.bind(this);
      this.state = {loadComplete:false,openDialogUserCreate:false,openLanguageDialog:false,openDialogRemoveUser:false,showPassword:false,textDialog:"",
        descriptionDialog:"",FormHelperTextPassword:"",loading:false,userError:"",users:[],levelAcess:[],language:[],userEdit:"",
        dialog:{name:"",lastName:"",email:"",levelAcess:"",birthDate:"",language:"",blocked:"",blockedReason:""}
      };

      window.listUsers = this;

  }

  //para buscar todos os usuarios via api
  async getAllUsers(){

      await api.users().then((res) => {
        this.setState({users:res.data});
      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

      });
    
  }

  //par apegar todos os levels de acesso
  async getAllLevelAcess(){

      await api.levelAcess().then((res) => {
        
        this.setState({levelAcess:res.data});

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

      });

  }

  //para buscar as linguagens disponíveis
  async listLanguages(){

      await api.listLanguages().then((res) => {
        
        this.setState({language:res.data});

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

      });

  }

  //para atualizar os dados do usuario no sistema.
  async putUser(){

      var data = JSON.parse(JSON.stringify(this.state.dialog));
      data.birthDate = moment(data.birthDate,'DD/MM/YYYY').format('YYYY-MM-DD');

      await api.updateUser(this.state.userEdit,data).then((res) => {
          
        this.setState({openDialogUserCreate:false,loading:false});

        var users = this.state.users;
        users.forEach(function(item,index,array){ if(parseInt(res.data.user.id) === parseInt(item.id)){ users[index] = res.data.user; }});
        this.setState({users:users});

        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">As alterações foram salvas com sucesso.</span><span data-notify="message">Informe ao usuário para relogar no sistema.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
        this.refs.notificationAlert.notificationAlert(options);

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

        this.setState({loading:false});

          if(error.response.status === 400){
          this.setState({userError:""});
          this.setState({userError:error.response.data.msg});

        }

      });

  }

  //para cadastrar o usuario no banco de dados
  async postUser(){

      var data = JSON.parse(JSON.stringify(this.state.dialog));
      data.birthDate = moment(data.birthDate).format('YYYY-MM-DD');

      await api.postUser(data).then((res) => {

        this.setState({openDialogUserCreate:false,loading:false});
        this.getAllUsers();

        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">O usuário foi cadastrado com sucesso.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
        this.refs.notificationAlert.notificationAlert(options);

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

        this.setState({loading:false});

         if(error.response.status === 400){

          this.setState({userError:""});
          this.setState({userError:error.response.data.msg});

        }

      });

  }

  //para editar os dados do usuario
  editUser(id){

      this.setState({userError:"",userEdit:id,openDialogUserCreate:true,textDialog:"Editar usuário",descriptionDialog:"",showPassword:false,FormHelperTextPassword:"Deixe em branco caso não queira alterar."});
      this.state.users.forEach(function(item,index,array){ 

        if(parseInt(item.id) === parseInt(id)){ 
        
        var item2 = JSON.stringify(item);
        item2 = JSON.parse(item2);

          delete item2.createDate;
          delete item2.updateDate;
          delete item2.id; 

          item2.birthDate = moment(item2.birthDate).format('DD/MM/YYYY');

          this.setState({dialog:item2});

        } 

      }.bind(this));

  }

    //Para cadastrar um usuário novo
  newUser(){

    this.setState({dialog:{name:"",lastName:"",email:"",levelAcess:"",birthDate:"",language:"",blocked:"0",blockedReason:""},FormHelperTextPassword:"",userError:"",userEdit:"",openDialogUserCreate:true,textDialog:"Cadastrar usuário",descriptionDialog:"",showPassword:false});
      
  }


  //quando clicar no botão
  saveUser(e){

      e.preventDefault();
      this.setState({loading:true});

      var dialog = this.state.dialog;

      dialog.language = $("#linguagemPadrao").val();
      dialog.blocked = $("#bloquearAcesso").val();
      dialog.levelAcess = $("#nivelAcesso").val();

      this.setState({dialog:dialog});

      if(this.state.userEdit === ""){ this.postUser(); }else{ this.putUser(); }

  }
 

  //para remover um usuario do sistema
  removerUser(id){

    this.setState({openDialogRemoveUser:true,userDeletion:id});

  }

  //para enviar os dados do usuario para atualização
  async removeUserApi(){

      this.setState({openDialogRemoveUser:false});
      await api.deleteUser(this.state.userDeletion).then((res) => {
          
        this.getAllUsers();
        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">O usuário foi removido.</span></div>),type: 'success',icon: "fas fa-exclamation",autoDismiss: 7};
        this.refs.notificationAlert.notificationAlert(options);

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

        this.setState({loading:false});

        if(error.response.status === 400){

          let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Não foi possível remover o usuário.</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
          this.refs.notificationAlert.notificationAlert(options);

        }

      });

  }

  //para criar um novo usuario no sistem,
  async componentDidMount(){
  
    await this.listLanguages();
    await this.getAllUsers();
    await this.getAllLevelAcess();
    this.setState({loadComplete:true});

  }

  selectLanguage(id){

    var dialog = this.state.dialog;
    dialog.language = id;
    this.setState({openLanguageDialog:false,dialog:dialog});

  }
 
  render(){

    const handleChange = name => event => {
        var dialog = this.state.dialog;
        dialog[name] = event.target.value;
        this.setState({dialog:dialog});
    }

    var stblock;

    if(this.state.dialog.blocked === "1"){ stblock={}; }else{ stblock={display:"none"}; }

    return(
      <React.Fragment>
        <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
        <SimpleHeader name="Usuários" way={[{name:"Usuários",url:"/users"}]} buttons={<React.Fragment></React.Fragment>} />
        <Container className="mt--6" fluid>
          <Card>
            <CardHeader className="border-0">
              <Row>
                <Col xs="6"><h3 className="mb-0">Lista de usuários</h3></Col>
                <Col className="text-right" xs="6">
                  <Button className="btn-neutral btn-round btn-icon" color="default" href="#pablo" id="tooltip969372949" onClick={() => this.newUser()} size="sm">
                    <span className="btn-inner--icon mr-1"><i class="fas fa-user-plus"></i></span>
                    <span className="btn-inner--text">Criar usuário</span>
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th>Nome</th>
                  <th>E-mail</th>
                  <th><center>Acesso bloqueado</center></th> 
                  <th><center>Data de nascimento</center></th>
                  <th>Nível de acesso</th>
                  <th>Acões</th>
                </tr>
              </thead>
              <tbody>
              {

                this.state.users.map((value,index) => {

                  var accessLevel,b,key = "user_key_"+value.id;
                  var tooltip = "tooltip_user_key_"+value.id;
                  var tooltip2 = "tooltip2_user_key_"+value.id;

                  if(parseInt(value.blocked) === 1){ 

                    b=<strong style={{color:"red"}}>Sim</strong>; 

                  }else{ 

                    b=<strong style={{color:"green"}}>Não</strong>; 

                  }
 
                  this.state.levelAcess.forEach(function(item,index,array){
                    if(item.id === value.levelAcess){accessLevel = item.name;}
                  });
              
                  return(<tr key={key}>
                      <td className="table-user">{value.name} {value.lastName}</td>
                      <td>{value.email}</td>
                      <td><center>{b}</center></td>
                      <td><center>{moment(value.birthDate,'YYYY-MM-DD').format('DD/MM/YYYY')}</center></td>
                      <td>{accessLevel}</td>
                      <td className="table-actions">
                        
                        <a onClick={ () => this.editUser(value.id)} className="table-action" href="#pablo" id={tooltip}  ><i className="fas fa-user-edit" /></a>
                        <UncontrolledTooltip delay={0} target={tooltip}>Editar usuário</UncontrolledTooltip>
                        
                        <a onClick={ () => this.removerUser(value.id)} className="table-action table-action-delete" href="#pablo" id={tooltip2} ><i className="fas fa-trash" /></a>
                        <UncontrolledTooltip delay={0} target={tooltip2}>Deletar usuário</UncontrolledTooltip>

                      </td>
                    </tr>)

                  })}
              </tbody>
            </Table>
          </Card>
        </Container>
        <Modal className="modal-dialog-centered" isOpen={this.state.openDialogUserCreate} >
          <div className="modal-body p-0">
            <Card className="bg-secondary border-0 mb-0">
            <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">{this.state.textDialog}</h6>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.setState({openDialogUserCreate:false})}><span aria-hidden={true}>×</span> </button>
          </div>
              <CardBody className="px-lg-5">
                <Form role="form" type="post" onSubmit={ (event) => this.saveUser(event) }>
                  {
                    (this.state.userError) &&
                    <Alert color="danger" className="animated tada"><strong>Ops!</strong> {this.state.userError}</Alert>
                  }
                  <Row>
                      <Col md={6}>
                        <h5>Nome</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedName})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('name')} value={ this.state.dialog.name } onFocus={() => this.setState({ focusedName: true }) } onBlur={() => this.setState({ focusedName: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <h5>Sobrenome</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedLastName})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('lastName')} value={ this.state.dialog.lastName } onFocus={() => this.setState({ focusedLastName: true }) } onBlur={() => this.setState({ focusedLastName: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                  </Row>
                  <Row>
                      <Col md={6}>
                        <h5>E-mail</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedEmail})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-email-83" /></InputGroupText></InputGroupAddon>
                            <Input onChange={handleChange('email')} value={ this.state.dialog.email } type="email" onFocus={() => this.setState({ focusedEmail: true }) } onBlur={() => this.setState({ focusedEmail: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <h5>Data de nascimento</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedNascimento})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-calendar-grid-58" /></InputGroupText></InputGroupAddon> 
                            <InputMask onChange={handleChange('birthDate')} value={ this.state.dialog.birthDate } className="form-control" mask="99/99/9999" maskChar=" " onFocus={() => this.setState({ focusedNascimento: true }) } onBlur={() => this.setState({ focusedNascimento: false }) }/>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup >
                        <h5>Nível de acesso</h5>
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-badge" /></InputGroupText></InputGroupAddon>
                          <Input id="nivelAcesso" type="select" className="" value={this.state.dialog.levelAcess} onChange={handleChange('levelAcess')}>
                            {
                              this.state.levelAcess.map((value,index) => {return(<option key={index} value={value.id}>{value.name}</option>)})
                            }
                          </Input>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className={classnames("mb-3",{focused:this.state.focusedEmail})}>
                        <h5>Linguagem padrão</h5>
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-world-2" /></InputGroupText></InputGroupAddon>
                          <Input id="linguagemPadrao" type="select" className="" value={this.state.dialog.language} onChange={handleChange('language')}>
                              {
                                this.state.language.map((value,index) => {return(<option key={index} value={value.id}>{value.name}</option>)})
                              }
                          </Input>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <h5>Bloquear acesso?</h5>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-lock-circle-open" /></InputGroupText></InputGroupAddon>
                      <Input id="bloquearAcesso" type="select" className="" value={this.state.dialog.blocked} onChange={handleChange('blocked')}>
                          <option value="0" SELECTED>Não</option>
                          <option value="1">Sim</option>
                      </Input>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className={classnames("mb-3",{focused:this.state.focusedEmail})+" "} style={stblock}>
                    <h5>Motivo do bloqueio</h5>
                    <Input className="form-control-alternative" placeholder="O motivo será exibido se o usuário tentar logar." rows="3" type="textarea" />
                  </FormGroup>
                  <FormGroup className={classnames({focused: this.state.focusedPassword})}>
                    <h5>Senha de acesso</h5>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText><i className="ni ni-lock-circle-open" /></InputGroupText>
                      </InputGroupAddon>
                      <Input className="form-control-alternative" onChange={handleChange('password')} placeholder="Password" type="password" onFocus={() => this.setState({ focusedPassword: true }) } onBlur={() => this.setState({ focusedPassword: false }) } />
                    </InputGroup>
                    {
                      (this.state.FormHelperTextPassword !== "") &&
                      <h5>{this.state.FormHelperTextPassword}</h5>
                    }
                  </FormGroup>
                  <div className="text-center">
                    <Button className="my-4" color="default" type="button" onClick={ () => this.setState({openDialogUserCreate:false}) } >Cancelar</Button>
                    <Button className="my-4" color="primary" type="submit">{this.state.textDialog}</Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
        <Modal className="modal-dialog-centered modal-danger" contentClassName="bg-gradient-danger" isOpen={this.state.openDialogRemoveUser} >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">Sua atenção é requirida.</h6>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.toggleModal("notificationModal")}>
              <span aria-hidden={true} onClick={ () => this.setState({openDialogRemoveUser:false}) }>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <i class="fas fa-exclamation-triangle fa-x3" style={{fontSize:'45px'}}></i>
              <h4 className="heading mt-4">VOCÊ DEVE LER ISTO!</h4>
              <p>Se você prosseguir, todos os dados do usuário serão removidos, essa ação é irreversível. É recomendavel <strong style={{fontWeight:'700'}}>bloquear</strong> o usuário ao invés de remove-lo. </p>
            </div>
          </div>
          <div className="modal-footer">
            <Button className="btn-white" color="default" type="button" onClick={ () => this.removeUserApi() }>Ok, Remova</Button>
            <Button className="text-white ml-auto" color="link" data-dismiss="modal" type="button" onClick={ () => this.setState({openDialogRemoveUser:false}) }>
              Cancelar e fechar
            </Button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
export default Tables;