import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  ListGroupItem,
  ListGroup,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";

class AdminNavbar extends React.Component {
  // function that on mobile devices makes the search open
  openSearch = () => {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function() {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  };
  // function that on mobile devices makes the search close
  closeSearch = () => {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  };
  render() {
    return (
      <>
        <Navbar
          className={classnames(
            "navbar-top navbar-expand border-bottom",
            { "navbar-dark bg-info": this.props.theme === "dark" },
            { "navbar-light bg-secondary": this.props.theme === "light" }
          )}
        >
          <Container fluid>
            <Collapse navbar isOpen={true}>
              <Form
                className={classnames(
                  "navbar-search form-inline mr-sm-3",
                  { "navbar-search-light": this.props.theme === "dark" },
                  { "navbar-search-dark": this.props.theme === "light" }
                )}
              >
                <FormGroup className="mb-0">
                  <InputGroup className="input-group-alternative input-group-merge">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Search" type="text" />
                  </InputGroup>
                </FormGroup>
                <button aria-label="Close" className="close" type="button" onClick={this.closeSearch} >
                  <span aria-hidden={true}>×</span>
                </button>
              </Form>

              <Nav className="align-items-center ml-md-auto" navbar>
                <NavItem className="d-xl-none">
                  <div
                    className={classnames(
                      "pr-3 sidenav-toggler",
                      { active: this.props.sidenavOpen },
                      { "sidenav-toggler-dark": this.props.theme === "dark" }
                    )}
                    onClick={this.props.toggleSidenav}
                  >
                    <div className="sidenav-toggler-inner">
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                    </div>
                  </div>
                </NavItem>
                <NavItem className="d-sm-none">
                  <NavLink onClick={this.openSearch}>
                    <i className="ni ni-zoom-split-in" />
                  </NavLink>
                </NavItem>
                <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link" color="" tag="a" hidden>
                    <i className="ni ni-bell-55" />
                  </DropdownToggle>
                  <DropdownMenu
                    className="dropdown-menu-xl py-0 overflow-hidden"
                    right
                  >
                    <div className="px-3 py-3">
                      <h6 className="text-sm text-muted m-0">
                        You have <strong className="text-info">13</strong>{" "}
                        notifications.
                      </h6>
                    </div>

                    <ListGroup flush>
                      <ListGroupItem
                        className="list-group-item-action"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        tag="a"
                      >
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              alt="..."
                              className="avatar rounded-circle"
                              src={''}
                            />
                          </Col>
                          <div className="col ml--2">
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <h4 className="mb-0 text-sm">John Snow</h4>
                              </div>
                              <div className="text-right text-muted">
                                <small>2 hrs ago</small>
                              </div>
                            </div>
                            <p className="text-sm mb-0">
                              Let's meet at Starbucks at 11:30. Wdyt?
                            </p>
                          </div>
                        </Row>
                      </ListGroupItem>
                      <ListGroupItem
                        className="list-group-item-action"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        tag="a"
                      >
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              alt="..."
                              className="avatar rounded-circle"
                              src={''}
                            />
                          </Col>
                          <div className="col ml--2">
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <h4 className="mb-0 text-sm">John Snow</h4>
                              </div>
                              <div className="text-right text-muted">
                                <small>3 hrs ago</small>
                              </div>
                            </div>
                            <p className="text-sm mb-0">
                              A new issue has been reported for Argon.
                            </p>
                          </div>
                        </Row>
                      </ListGroupItem>
                      <ListGroupItem
                        className="list-group-item-action"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        tag="a"
                      >
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              alt="..."
                              className="avatar rounded-circle"
                              src={''}
                            />
                          </Col>
                          <div className="col ml--2">
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <h4 className="mb-0 text-sm">John Snow</h4>
                              </div>
                              <div className="text-right text-muted">
                                <small>5 hrs ago</small>
                              </div>
                            </div>
                            <p className="text-sm mb-0">
                              Your posts have been liked a lot.
                            </p>
                          </div>
                        </Row>
                      </ListGroupItem>
                      <ListGroupItem
                        className="list-group-item-action"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        tag="a"
                      >
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              alt="..."
                              className="avatar rounded-circle"
                              src={''}
                            />
                          </Col>
                          <div className="col ml--2">
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <h4 className="mb-0 text-sm">John Snow</h4>
                              </div>
                              <div className="text-right text-muted">
                                <small>2 hrs ago</small>
                              </div>
                            </div>
                            <p className="text-sm mb-0">
                              Let's meet at Starbucks at 11:30. Wdyt?
                            </p>
                          </div>
                        </Row>
                      </ListGroupItem>
                      <ListGroupItem
                        className="list-group-item-action"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        tag="a"
                      >
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              alt="..."
                              className="avatar rounded-circle"
                              src={''}
                            />
                          </Col>
                          <div className="col ml--2">
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <h4 className="mb-0 text-sm">John Snow</h4>
                              </div>
                              <div className="text-right text-muted">
                                <small>3 hrs ago</small>
                              </div>
                            </div>
                            <p className="text-sm mb-0">
                              A new issue has been reported for Argon.
                            </p>
                          </div>
                        </Row>
                      </ListGroupItem>
                    </ListGroup>
                    <DropdownItem className="text-center text-info font-weight-bold py-3" href="#pablo" onClick={e => e.preventDefault()} >
                      View all
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link" color="" tag="a">

                    {

                        window.app.state.languages.map((value,index) => {
                          
                          if(window.app.state.languageSelected === value.id){ 

                            return(

                              <React.Fragment key={index}><img alt="..." src={'/flags/'+value.img} style={{height:'38px',cursor:'pointer'}}/></React.Fragment>) 

                            }else{ return(<React.Fragment key={index}></React.Fragment>) }
                          
                        })

                    }
 
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-lg dropdown-menu-dark bg-default" right >
                    <Row className="shortcuts px-4">
                      {

                        window.app.state.languages.map((value,index) => {
         
                          return(<Col style={{cursor:'pointer'}} key={value.id} className="shortcut-item" onClick={() => window.app.getLanguageTexts(value.file,value.id) } xs="4" tag="a" >
                                  <span style={{background:'#0000'}} className="shortcut-media avatar rounded-circle"> <img alt="..." src={'/flags/'+value.img} /> </span>
                                  <small style={{marginTop:"0px",color:'#fff'}}>{value.name}</small>
                                </Col>)

                        })

                      }
                    </Row>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
              <Nav className="align-items-center ml-auto ml-md-0" navbar  >
                <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link pr-0" color="" tag="a">
                    <Media className="align-items-center" style={{cursor:"pointer"}}>
                      <span className="avatar avatar-sm rounded-circle">
                        <img alt="..." src={''} />
                      </span>
                      <Media className="ml-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold">{ window.app.state.user.name }</span>
                      </Media>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem className="noti-title" header tag="div">
                      <h6 className="text-overflow m-0">{window.language('bem_vindo')}</h6>
                    </DropdownItem>
                    <DropdownItem href="#pablo" onClick={e => e.preventDefault()} >
                      <i className="ni ni-single-02" /> <span>Meu perfil</span>
                    </DropdownItem>
                    <DropdownItem href="#pablo" onClick={e => e.preventDefault()} >
                      <i className="ni ni-settings-gear-65" /> <span>Preferencias</span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem style={{cursor:'pointer'}} onClick={ () => window.app.removeSession() } >
                      <i className="ni ni-user-run" />
                      <span>Sair</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}
AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark"
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"])
};

export default AdminNavbar;
