import React from "react";
// react library for routing
import { Route, Switch } from "react-router-dom";
// core components
import AdminNavbar from "./../components/Navbars/AdminNavbar.jsx";
//import AdminFooter from "components/Footers/AdminFooter.jsx";
import Sidebar from "./../components/Sidebar/Sidebar.jsx";

import routes from "./../routes.js";
import NewCourse from "./../views/pages/NewCourse.jsx";
import Users from "./../views/pages/Users.jsx";
import Course from "./../views/pages/Course.jsx";
import ListCourse from "./../views/pages/ListCourse.jsx";
import NewLesson from "./../views/pages/NewLesson.jsx";
import ListLesson from "./../views/pages/ListLesson.jsx";
//import ListLesson2 from "./../views/pages/ListLesson2.jsx"
import License from "./../views/pages/License.jsx";
import Videos from "./../views/pages/course/Videos.js"; 
import routesNew from "./../routesNew.js";
 
import MountQuiz from "./../views/pages/course/MountQuiz.jsx"; 
import CourseQuiz from "./../views/pages/course/CourseQuiz.jsx";
import Permission from "./../views/pages/Permission.jsx"; 
import AssessmentMethods from "./../views/pages/AssessmentMethods.jsx";
import ManagerChapter from "./../views/pages/coursetypes/mobile/ManagerChapter.jsx";

import ManagerLessonsPage from "./../views/pages/coursetypes/mobile/ManagerLessonsPage.jsx";
import ManagerMedia from "./../views/pages/coursetypes/mobile/ManagerMedia.jsx";


import "./../assets/css/ManagerChapter.css";

class Admin extends React.Component {

  state = {sidenavOpen: true};

  constructor(props) {
    
    super(props);
    window.Admin = this;

  }

   //Para mostrar as rotas do menu
   getRoutes(){
 
    var routes = routesNew(this.props.permission,this.props.translate);
    return routes;

  }

  componentDidUpdate(e){}

  

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  // toggles collapse between mini sidenav and normal
  toggleSidenav = e => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    this.setState({ sidenavOpen: !this.state.sidenavOpen });
  };

  getNavbarTheme = () => { return this.props.location.pathname.indexOf("admin/alternative-dashboard") === -1? "dark": "light";};

  render() {
    return (
      <React.Fragment>

        <Sidebar {...this.props} routes={this.getRoutes()} toggleSidenav={this.toggleSidenav} sidenavOpen={this.state.sidenavOpen} logo={{ innerLink: "/", imgSrc: ' ', imgAlt: "..." }} />
        <div className="main-content" ref="mainContent" onClick={this.closeSidenav} >

          <AdminNavbar {...this.props} theme={this.getNavbarTheme()} toggleSidenav={this.toggleSidenav} sidenavOpen={this.state.sidenavOpen} brandText={this.getBrandText(this.props.location.pathname)} />
        
            <Switch>

              <Route exact={true} path='/course/:course/quizzes/:quiz' component={MountQuiz}   />
              <Route exact={true} path='/course/:course/quizzes' component={CourseQuiz} />
              <Route exact={true} path='/course/newcourse' component={NewCourse} />
              <Route exact={true} path='/course/edit/:id' component={NewCourse} />
              <Route exact={true} path="/course/:course/videos" component={Videos} />


              <Route exact={true} path="/course/:course/audios" component={(props) => <ManagerMedia type={'audio'} {...props} />} />
              <Route exact={true} path="/course/:course/images" component={(props) => <ManagerMedia type={'image'} {...props} />} />
              <Route exact={true} path="/course/:course/videosfile" component={(props) => <ManagerMedia type={'video'} {...props} />} />


              
      

              <Route exact={false} path="/course/:course/unit/:unit/lesson/:lesson" component={NewLesson} /> 
              {/*<Route exact={true} path="/course/:course/unit/:unit/mountlesson" component={ListLesson} />*/}
              <Route exact={true} path="/course/:course/unit/:unit/mountlesson" component={ListLesson} />
              <Route exact={true} path="/course/:course/unit/:unit/managerchapter" component={ManagerChapter} />
              <Route exact={false} path={["/course/:course/unit/:unit/managerchapter/:page/:lesson","/course/:course/unit/:unit/managerchapter/:page"]} component={ManagerLessonsPage} />
              <Route exact={true} path="/course/:course" component={Course} />
              <Route exact={true} path="/course" component={ListCourse} />
              <Route exact={true} path="/users" component={Users} />
              <Route exact={true} path="/license" component={License} />
              <Route exact={true} path="/permission" component={Permission} />
              <Route exact={true} path="/assessment_methods" component={AssessmentMethods} />




            </Switch>

        </div>

        {this.state.sidenavOpen ? ( <div className="backdrop d-xl-none" onClick={this.toggleSidenav} /> ) : null}

      </React.Fragment>
    );
  }
}

export default Admin;