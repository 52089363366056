import React from "react";
import {UncontrolledAlert,CardBody,FormGroup,Input,Button,Card,CardHeader,Progress,Container,Row,Col,Modal,Alert,InputGroup} from "reactstrap";
import api from "./../../../Api.js";

const Swal = require('sweetalert2');

class Level extends React.Component {

	constructor(props){

        super(props);
        this.state = {};

        this.saveLevel = this.saveLevel.bind(this);
        this.postLevel = this.postLevel.bind(this);
        this.putLevel = this.putLevel.bind(this);
        this.deleteCourseLevel = this.deleteCourseLevel.bind(this);
        
	}

    async saveLevel(){

        console.log('salvando level');
		if(this.state.dialogLevel.id === undefined){ this.postLevel(); }else{ this.putLevel(); }

	}

    postLevel(){

		api.postCourseLevel(this.state.dialogLevel).then(() => {

			this.setState({openNewCourseLevel:false});
			this.props.getCouseLevels();

		}).catch((error) => {

			this.setState({errorNewModality:error.response.data.msg});

		});

	}

	putLevel(){

		api.putCourseLevel(this.state.dialogLevel.id,this.state.dialogLevel).then(() => {
			
			this.setState({openNewCourseLevel:false});
			this.props.getCouseLevels();

		}).catch((error) => {

			this.setState({errorNewModality:error.response.data.msg});

		});

	}
	 
    deleteCourseLevel(id){

        Swal.fire({
            title: 'Tem certeza?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, remover!',
            cancelButtonText:"Não, cancelar"
          }).then((result) => {
      
            if(result.value){
                
                api.deleteCourseLevel(id).then((res) => {
       
                    this.props.getCouseLevels();

                }).catch(error => {
        
                    console.log(error.data);
                    console.log(error);
                    console.log(error.response);
        
                });
                 
      
            }
      
        });

    }

	render(){
        

        this.state = this.props.state;
        this.setState = this.props.setState;

    	return(
		<React.Fragment> 
            <Modal className="modal-dialog-centered" contentClassName="bg-gradient-danger" isOpen={this.state.openModalEditCourseLevel} style={{maxWidth:'1100px'}}>
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                    <div className="modal-header">
                        <h6 className="modal-title" id="modal-title-default">Tipos de nível</h6>
                        <button onClick={() => { this.setState({openNewCourseLevel:true,dialogLevel:{name:"",errorNewCourseType:""}}); }} className="btn btn-sm btn-secondary" style={{position:'absolute',top:'45px'}}>Criar nível</button>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.setState({openModalEditCourseLevel:false})}><span aria-hidden={true}>×</span> </button>
                    </div>
                    <CardBody className="px-lg-5">
                                
                        <table className="align-items-center table-flush table">
                            <thead className="thead-light">
                                <tr>
                                    <th>#</th>
                                    <th>Nome</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    this.state.couseLevels.map((value,index) => {

                                        return(
                                        <React.Fragment>
                                            <tr>
                                                <th>{index+1}</th>
                                                <th>{value.name}</th>
                                                <th>
                                                    <button onClick={() => { this.setState({openNewCourseLevel:true,dialogLevel:value,errorNewCourseLevel:""}); }} className="btn-secondary btn-round btn-icon btn btn-sm"><i class="far fa-edit"></i> Editar</button>
                                                    <button onClick={() => { this.deleteCourseLevel(value.id); }} className="btn-danger btn-round btn-icon btn btn-sm"><i class="fas fa-trash"></i></button>
                                                </th>
                                            </tr>	
                                        </React.Fragment>)

                                    })

                                }
                            </tbody>
                        </table>
                        {

                            (this.state.couseLevels.length === 0) &&
                            <center style={{margin:'20px'}}>Nenhum nível de curso foi encontrado.</center>

                        }
                    </CardBody>
                    </Card>
                </div>	 
            </Modal>
            <Modal className="modal-dialog-centered" contentClassName="bg-gradient-danger" isOpen={this.state.openNewCourseLevel} >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                    <div className="modal-header">
                        <h6 className="modal-title" id="modal-title-default">{(this.state.dialogLevel.id === undefined ? 'Cadastrar nível':'Editar nível')}</h6>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.setState({dialogLevel:{name:"",description:""},openNewCourseLevel:false})}><span aria-hidden={true}>×</span> </button>
                    </div>
                    <CardBody className="px-lg-4">
                        {

                            (this.state.errorNewLevel !== undefined && this.state.errorNewLevel !== "") &&
                            <Col md={12}>
                                <Alert color="danger">
                                    <strong>Ops!</strong> {this.state.errorNewLevel}
                                </Alert>
                            </Col>

                        }
                        
                        <form onSubmit={(e) => { console.log('ds1'); e.preventDefault(); this.saveLevel(); }}>
                            <Col md={12}>
                                <h5>Nome:</h5>
                                <FormGroup className={'mb-3'} >
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <input className="form-control" type="text" onChange={(e) => { this.setState({dialogLevel:{...this.state.dialogLevel,name:e.target.value}}); }} value={ this.state.dialogLevel.name } />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <button type="submit" className="btn btn-primary" style={{width:'100%'}}>{(this.state.dialogLevel.id === undefined ? 'Cadastrar nível':'Salvar alterações')}</button>
                            </Col>
                        </form>
                    </CardBody>
                    </Card>
                </div>	 
            </Modal>
		</React.Fragment>)

    }
}

export default Level;