import React from "react";
import {Button,Card,CardBody,Form} from "reactstrap";
import {Modal} from "reactstrap";
import reduxHelper from "./../../../helper/index.js";
import NotificationAlert from "react-notification-alert";

class CourseLicenseAvailable extends React.Component{

	constructor(props){

		super(props);
       
        this.state = {dialogUnit:{},dialogLesson:{},courses:[],permChecks:[]};
        this.helper = new reduxHelper(this.props);

        this.getAllCourses = this.getAllCourses.bind(this);
        this.getCourseLicense = this.getCourseLicense.bind(this);
        this.saveCourses = this.saveCourses.bind(this);

		window.licensecourseavailable = this;

	}

    //Para pegar todos os cursos
    async getAllCourses(){

        var data = await this.helper.course().listCourses();
        if(data.status === 200){

            await this.setState({courses:data.data});
            console.log(this.state);

        }

    }
    //as
    //Para pegar os cursos disponíveis nessa licença
    async getCourseLicense(){
        
        this.setState({license:this.props.state.courseLicense}); 

        var data = await this.helper.license().getCourseLicense(this.props.state.courseLicense);
        if(data.status === 200){

            this.setState({permChecks:data.data});

        }

    }

    //Para salvar os novos cursos
    async saveCourses(event){

        event.preventDefault();

        var courses = this.state.permChecks; 

        console.log(this.props.state.courseLicense);
        console.log(courses);

        var data = await this.helper.license().putCourseLicense(this.props.state.courseLicense,courses);

        if(data.status === 200){

            this.props.setState({openModaCourseLicense:false});
  
            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Os cursos da licença foram atualizados.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

        }
 
        if(data.status === 400){

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Não foi possível remover o usuário.</span><span data-notify="message">{data.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

        }
       

    }

    componentDidMount(){ this.getAllCourses();  }

    componentDidUpdate(){

      if(this.state.license !== this.props.state.courseLicense){ this.getCourseLicense(); }
      //console.log(this.state.license+'!=='+this.props.state.courseLicense);

    }

	render(){
 
		return(
		<React.Fragment>

            <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>

	        <Modal className="modal-dialog-centered" isOpen={this.props.state.openModaCourseLicense} >
	          <div className="modal-body p-0">
	            <Card className="bg-secondary border-0 mb-0">
	            <div className="modal-header">
	            <h6 className="modal-title" id="modal-title-default">Cursos disponíves</h6>
	            <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.props.setState({openModaCourseLicense:false})}><span aria-hidden={true}>×</span> </button>
	          </div>
                    <CardBody className="px-lg-5">
                        <Form role="form" type="post" onSubmit={ (event) => this.saveCourses(event) }>

                            {

                                this.state.courses.map((value,index) => {
                                    
                                    var id = "input_permition_"+value.id; 

                                    //Para mostrar apenas as permissões do grupo que estou editando no momnento 
                                    if(this.state.showPermitionGrupo !== value.groupName){ return <React.Fragment key={id}></React.Fragment> }

                                    var check = false;
                                    if(this.state.permChecks.indexOf(value.id) !== -1){ 
                                        
                                        check = true;
                                    
                                    }
 

                                    return(<React.Fragment key={id}>
                                        <div className="custom-control custom-checkbox">
                                            <input onChange={ (event) => {

                                                    var permChecks = this.state.permChecks;
                                                    if(event.target.checked === true){

                                                        permChecks.push(value.id);
                                                        this.setState({permChecks});

                                                    }else{

                                                        permChecks.splice(permChecks.indexOf(value.id),1);
                                                        this.setState({permChecks});

                                                    }

                                                }}

                                            checked={check} className="custom-control-input inputCourseAvailable" id={id} value={value.id} type="checkbox" />
                                            <label className="custom-control-label" htmlFor={id}>{value.name}</label>
                                        </div>
                                    </React.Fragment>)

                                })

                            }

                            <div className="text-center">
                                <Button className="my-4" color="default" type="button" onClick={ () => this.props.setState({openModaCourseLicense:false}) } >Cancelar</Button>
                                <Button className="my-4" color="primary" type="submit">Salvar alterações</Button>
                            </div>

                        </Form>
                    </CardBody>
	            </Card>
	          </div>
	        </Modal>
	    
        </React.Fragment>

		)

	}

}

export default CourseLicenseAvailable;