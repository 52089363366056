import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
const arrayMove = require('array-move');
const uuidv4 = require('uuid/v4');


const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,

  // change background colour if dragging
  //background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({display:'flex',/*background: isDraggingOver ? 'lightblue' : 'lightgrey',*/ });

class CreateWithPhrase extends Component{

    constructor(props) {

        super(props);
        this.state = {};

        this.state.wordids = [];

        this.onDragEnd = this.onDragEnd.bind(this);
        this.add = this.add.bind(this);
        this.remove = this.remove.bind(this);

        window.createwithphrase = this;

    }

    add(){

        this.props.state.dialogLesson.createwithphrase.words.push('');
        this.setState({a:''});

    }

    remove(index){

        this.props.state.dialogLesson.createwithphrase.words.splice(index,1);
        this.setState({a:''});

    }

    onDragEnd(data){
       
        this.props.state.dialogLesson.createwithphrase.words = arrayMove(this.props.state.dialogLesson.createwithphrase.words,data.source.index,data.destination.index);
        this.state.wordids = arrayMove(this.state.wordids,data.source.index,data.destination.index);
        this.setState({a:""});

    }
    
    render() {

        const createwithphrase = this.props.state.dialogLesson.createwithphrase;
        if(createwithphrase === undefined){

            this.props.state.dialogLesson.createwithphrase = {text:"",audio:"",words:[]};
  
        }

        const words = this.props.state.dialogLesson.createwithphrase.words;
  
        return(
        <React.Fragment>
 
            <div className="row">
                <div className="col-md-6">
                    <h5>Texto da questão:</h5>
                    <input class="form-control-alternative form-control" value={this.props.state.dialogLesson.createwithphrase.text} onChange={(e) => { this.props.state.dialogLesson.createwithphrase.text = e.target.value; this.setState({a:''}); }} />
                </div>
                <div className="col-md-6">
                    <h5>Audio:</h5>
                    <select class="form-control-alternative form-control" value={this.props.state.dialogLesson.createwithphrase.audio} onChange={(e) => { this.props.state.dialogLesson.createwithphrase.audio = e.target.value; this.setState({a:''}); }}>
                        <option value=""></option>
                        {

                            this.props.state.audios.map((value,index) => {

                                return(<option value={value.id}>{value.name}</option>)

                            })

                        } 
                    </select>
                </div>
            </div>

            <div style={{marginTop:'20px'}}><h5>Palavras:</h5></div>
            {

                (words.length === 0) &&
                <React.Fragment>
                    <center><h4>Nenhuma palavra foi cadastrada.</h4></center>
                </React.Fragment>

            }
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                {(provided, snapshot) => (
                    <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps} >
                    {
                    
                    words.map((item, index) => {

                        if(this.state.wordids[index] === undefined){ this.state.wordids[index] = uuidv4(); }
                        
                        return(<Draggable key={this.state.wordids[index]+''} draggableId={this.state.wordids[index]+''} index={index}>
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps}  style={getItemStyle(snapshot.isDragging,provided.draggableProps.style)}>
                                        <div> 
                                            <input type="text" className="form-control-alternative form-control" value={item} onChange={(e) => { this.props.state.dialogLesson.createwithphrase.words[index] = e.target.value; this.setState({a:''}); }}/>
                                        </div> 
                                        <div style={{display:'flex',alignItems:'center',justifyContent:'center',marginTop:'10px'}}>
                                            <i {...provided.dragHandleProps} className="fas fa-arrows-alt" style={{marginRight:'3px'}}></i>
                                            <i onClick={() => this.remove(index)} className="far fa-trash-alt" style={{marginLeft:'3px',cursor:'pointer'}}></i>
                                        </div>
                                    </div>
                                )}
                            </Draggable>)

                    })
                        
                        /*words.map((item, index) =>{
                            
                        ))*/
                    
                    }
                    {provided.placeholder}
                    </div>
                )}
                </Droppable>
            </DragDropContext>

            <div>
                <div><h4>Resposta formada:</h4></div>
                <div>
                {
                    
                    words.map((item) => { return(item+' ') })

                }
                </div>
            </div>

            <center><button style={{width:'130px'}} color="primary" onClick={() => this.add()} className="btn btn-sm btn-secondary"><i className="fas fa-plus"></i> Adicionar</button></center>

        </React.Fragment>
        );
    }

}

export default CreateWithPhrase;