import React from "react";
import {UncontrolledAlert,CardBody,FormGroup,Input,Button,Card,CardHeader,Progress,Container,Row,Col,Modal,Alert,InputGroup} from "reactstrap";
import api from "./../../../Api.js";

const Swal = require('sweetalert2');

class Modality extends React.Component {

	constructor(props){

        super(props);
        
        this.saveModality = this.saveModality.bind(this);
        this.postModality = this.postModality.bind(this);
        this.putModality = this.putModality.bind(this);
        this.deleteModality = this.deleteModality.bind(this);

	}

    saveModality(){

		if(this.state.dialogModality.id === undefined){ this.postModality(); }else{ this.putModality(); }

	}

    postModality(){

		api.postModality(this.state.dialogModality).then(() => {

			this.setState({openNewModality:false});
			this.props.getModalitys();

		}).catch((error) => {

			this.setState({errorNewModality:error.response.data.msg});

		});

	}

	putModality(){

		api.putModality(this.state.dialogModality.id,this.state.dialogModality).then(() => {
			
			this.setState({openNewModality:false});
			this.props.getModalitys();

		}).catch((error) => {

			this.setState({errorNewModality:error.response.data.msg});

		});

	}

    deleteModality(id){

        Swal.fire({
            title: 'Tem certeza?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, remover!',
            cancelButtonText:"Não, cancelar"
          }).then((result) => {
      
            if(result.value){
                
                api.deleteModality(id).then((res) => {
       
                    this.props.getModalitys();

                }).catch(error => {
        
                    console.log(error.data);
                    console.log(error);
                    console.log(error.response);
        
                });
                 
      
            }
      
        });

    }

	render(){
        

        this.state = this.props.state;
        this.setState = this.props.setState;

    	return(
		<React.Fragment> 
 

            <Modal className="modal-dialog-centered" contentClassName="bg-gradient-danger" isOpen={this.state.openModalEditModality} style={{maxWidth:'1100px'}}>
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                    <div className="modal-header">
                        <h6 className="modal-title" id="modal-title-default">Lista de modalidades</h6>
                        <button onClick={() => { this.setState({openNewModality:true,dialogModality:{name:"",description:"",errorNewModality:""}}); }} className="btn btn-sm btn-secondary" style={{position:'absolute',top:'45px'}}>Criar modalidade</button>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.setState({openModalEditModality:false})}><span aria-hidden={true}>×</span> </button>
                    </div>
                    <CardBody className="px-lg-5">
                                
                        <table className="align-items-center table-flush table">
                            <thead className="thead-light">
                                <tr>
                                    <th>#</th>
                                    <th>Nome</th>
                                    <th>Descrição</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    this.state.modalitys.map((value,index) => {

                                        return(
                                        <React.Fragment>
                                            <tr>
                                                <th>{index+1}</th>
                                                <th>{value.name}</th>
                                                <th>{value.description}</th>
                                                <th>
                                                    <button onClick={() => { this.setState({openNewModality:true,dialogModality:value,errorNewModality:""}); }} className="btn-secondary btn-round btn-icon btn btn-sm"><i class="far fa-edit"></i> Editar</button>
                                                    <button onClick={() => { this.deleteModality(value.id); }} className="btn-danger btn-round btn-icon btn btn-sm"><i class="fas fa-trash"></i></button>
                                                </th>
                                            </tr>	
                                        </React.Fragment>)

                                    })

                                }
                            </tbody>
                        </table>
                        {

                            (this.state.modalitys.length === 0) &&
                            <center style={{margin:'20px'}}>Nenhuma modalidade foi encontrada.</center>

                        }
                    </CardBody>
                    </Card>
                </div>	 
            </Modal>

            <Modal className="modal-dialog-centered" contentClassName="bg-gradient-danger" isOpen={this.state.openNewModality} >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                    <div className="modal-header">
                        <h6 className="modal-title" id="modal-title-default">{(this.state.dialogModality.id === undefined ? 'Cadastrar modalidade':'Editar modalidade')}</h6>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.setState({dialogModality:{name:"",description:""},openNewModality:false})}><span aria-hidden={true}>×</span> </button>
                    </div>
                    <CardBody className="px-lg-4">
                        {

                            (this.state.errorNewModality !== undefined && this.state.errorNewModality !== "") &&
                            <Col md={12}>
                                <Alert color="danger">
                                    <strong>Ops!</strong> {this.state.errorNewModality}
                                </Alert>
                            </Col>

                        }
                        
                        <form onSubmit={(e) => { console.log('ds1'); e.preventDefault(); this.saveModality(); }}>
                            <Col md={12}>
                                <h5>Nome:</h5>
                                <FormGroup className={'mb-3'} >
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <input className="form-control" type="text" onChange={(e) => { this.setState({dialogModality:{...this.state.dialogModality,name:e.target.value}}); }} value={ this.state.dialogModality.name } />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <h5>Descrição:</h5>
                                <FormGroup className={'mb-3'} >
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <textarea className="form-control" onChange={(e) => { this.setState({dialogModality:{...this.state.dialogModality,description:e.target.value}}); }} value={ this.state.dialogModality.description } ></textarea>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <button type="submit" className="btn btn-primary" style={{width:'100%'}}>{(this.state.dialogModality.id === undefined ? 'Cadastrar modalidade':'Salvar alterações')}</button>
                            </Col>
                        </form>
                    </CardBody>
                    </Card>
                </div>	 
            </Modal>

		</React.Fragment>)

    }
}

export default Modality;