import axios from 'axios';

//Não verifica o certificado ssl
var api = axios.create({secure: false });

var apis = {
	
	login: (data) => api.post(window.backend+'/auth/password',data,{headers:{session:sessionStorage.getItem('session')}}),
	users: () => api.get(window.backend+'/user',{headers:{session:sessionStorage.getItem('session')}}),
	levelAcess: () => api.get(window.backend+'/accesslevel',{headers:{session:sessionStorage.getItem('session')}}),
	listLanguages: () => api.get('/language/index.json'),
	languageText: (file) => api.get('/language/'+file),

	//para atualizar os dados de um usuario
	updateUser: (id,data) => api.put(window.backend+'/user/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

	//Parar criar um novo usuario
	postUser: (data) => api.post(window.backend+'/user/',data,{headers:{session:sessionStorage.getItem('session')}}),

	//Para remover o usuario
	deleteUser: (id) => api.delete(window.backend+'/user/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	//Para enviar arquivo
	uploadFile: (data) => api.delete(window.backend+'/upload/',data,{headers:{session:sessionStorage.getItem('session')}}),

	//Para criar um novo curso
	postCourse: (data) => api.post(window.backend+'/course/',data,{headers:{session:sessionStorage.getItem('session')}}),

	//Para listar todos os cursos
	course: () => api.get(window.backend+'/course/',{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar os dados de um curso
	getCourse: (id) => api.get(window.backend+'/course/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	//Para atualizar os dados de um curso
	putCourse: (id,data) => api.put(window.backend+'/course/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar os dados de uma unit
 	getUnit: (id) => api.get(window.backend+'/unit/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar as units de um curso
	getUnitCourse: (id) => api.get(window.backend+'/unit/course/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar as lessons de uma unit
	getLessonUnit: (id) => api.get(window.backend+'/lesson/unit/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar uma lesson especifica
	getLesson: (id) => api.get(window.backend+'/lesson/'+id,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para cadastrar uma unit
 	postUnit: (data) => api.post(window.backend+'/unit/',data,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para editar uma unit
 	putUnit: (id,data) => api.put(window.backend+'/unit/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para remover uma unit
 	deleteUnit: (id) => api.delete(window.backend+'/unit/'+id,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para alterar a ordem da unit
 	postLesson: (data) => api.post(window.backend+'/lesson/',data,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para cadastrar uma lesson
 	putLesson: (id,data) => api.put(window.backend+'/lesson/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para editar uma lesson
 	deleteLesson: (id) => api.delete(window.backend+'/lesson/'+id,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para remover uma lesson
 	changeOrderUnit: (item,index) => api.put(window.backend+'/unit/updateorder/'+item,{index:index},{headers:{session:sessionStorage.getItem('session')}}),

 	//Para remover uma lesson
 	changeOrderLesson: (item,index) => api.put(window.backend+'/lesson/updateorder/'+item,{index:index},{headers:{session:sessionStorage.getItem('session')}}),

 	//Para pegar todos os vídeos de um curso
 	getVideoCourse: (id) => api.get(window.backend+'/video/course/'+id,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para cadastrar um novo víode
 	postVideo: (data) => api.post(window.backend+'/video/',data,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para atualizar um video
 	putVideo: (id,data) => api.put(window.backend+'/video/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para remover um vídeo
 	deleteVideo: (id) => api.delete(window.backend+'/video/'+id,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para pegar os dados de um vídeo
 	dataVideo: (id) => api.get(window.backend+'/video/'+id,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para verificar o acesso do usuario no sisstema
 	verifyAcess: (id) => api.get(window.backend+'/auth/verify',{headers: {session: sessionStorage.getItem('session')}}),

 	//Para cadastrar um novo quiz no curso
 	postQuiz: (item) => api.post(window.backend+'/quiz/',item,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para pegar todos os quiz de um curso
 	getQuizCourse: (course) => api.get(window.backend+'/quiz/course/'+course,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para atualizar o quiz
 	putQuiz: (id,data) => api.put(window.backend+'/quiz/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para deletar um quiz
 	deleteQuiz: (id) => api.delete(window.backend+'/quiz/'+id,{headers:{session:sessionStorage.getItem('session')}}),
 	
 	//Para pegar as questões de um quiz
 	getQuizQuestions: (id) => api.get(window.backend+'/quizquestion/quiz/'+id,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para pegar os dados de um quiz especifico
 	getQuiz: (id) => api.get(window.backend+'/quiz/'+id,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para cadastrar uma nova questão
 	postQuestion: (data) => api.post(window.backend+'/quizquestion/',data,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para Atualizar uma questão
 	deleteQuestion: (id) => api.delete(window.backend+'/quizquestion/'+id,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para atualizar uma questão
 	putQuestion: (id,data) => api.put(window.backend+'/quizquestion/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para pegar todas as license
 	getAllLicense: (id) => api.get(window.backend+'/license/',{headers:{session:sessionStorage.getItem('session')}}),

 	//Para pegar todas as license
 	postLicense: (data) => api.post(window.backend+'/license/',data,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para pegar todas as license
 	putLicense: (id,data) => api.put(window.backend+'/license/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para pegar os niveis de acesso de um grupo
 	getAccessLevelGroup: (group) => api.get(window.backend+'/accesslevel/group/'+group,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para pergar todas as permissões
 	getAllPermission: () => api.get(window.backend+'/accesslevel/permission/',{headers:{session:sessionStorage.getItem('session')}}),

 	//Para cadastrar um grupo de permissão
 	postGroupPermission: (obj) => api.post(window.backend+'/accesslevel/group',obj,{headers:{session:sessionStorage.getItem('session')}}),

	//Para editar um grupo de permissão
 	putGroupPermission: (id,data) => api.put(window.backend+'/accesslevel/group/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para salvar as permissões do grupo
 	savePermissionGroup: (grupo,data)  => api.put(window.backend+'/accesslevel/save/permission/'+grupo,data,{headers:{session:sessionStorage.getItem('session')}}),

	//Para cadastrar o grupo de aula
	postLessonGroup: (data)  => api.post(window.backend+'/lessongroup',data,{headers:{session:sessionStorage.getItem('session')}}),

	//Para salvar o grupo de aula
	putLessonGroup: (id,data)  => api.put(window.backend+'/lessongroup/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar os cursos disponíveis em um licença
	getCourseLicense: (id)  => api.get(window.backend+'/license/listcourses/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	//Para atualizar a licença do curso
	putCourseLicense: (idLicense,courses)  => api.put(window.backend+'/license/savecourses/'+idLicense,{courses:courses},{headers:{session:sessionStorage.getItem('session')}}),

	//Para listar quais licenças usam o curso
	getListslicenseCourse: (course)  => api.get(window.backend+'/course/listslicense/'+course,{headers:{session:sessionStorage.getItem('session')}}),
	
	duplicateUnit: (data)  => api.post(window.backend+'/unit/copy/',data,{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar os métodos de avaliação
	getEvaluations: ()  => api.post(window.backend+'/evaluation/',{headers:{session:sessionStorage.getItem('session')}}),

	getEvaluation: (id)  => api.post(window.backend+'/evaluation/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	postEvaluations: (data)  => api.post(window.backend+'/evaluation/',data,{headers:{session:sessionStorage.getItem('session')}}),
	
	putEvaluations: (id,data)  => api.post(window.backend+'/evaluation/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	
	deleteEvaluations: (id)  => api.post(window.backend+'/evaluation/'+id,{headers:{session:sessionStorage.getItem('session')}}),
 
	postChapter: (data)  => api.post(window.backend+'/chapter/',data,{headers:{session:sessionStorage.getItem('session')}}),
	putChapter: (id,data)  => api.put(window.backend+'/chapter/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	deleteChapter: (id)  => api.delete(window.backend+'/chapter/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	getChapter: (id)  => api.get(window.backend+'/chapter/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	getChaptersUnit: (id)  => api.get(window.backend+'/chapter/fromunit/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	postPage: (data)  => api.post(window.backend+'/page/',data,{headers:{session:sessionStorage.getItem('session')}}),
	putPage: (id,data)  => api.put(window.backend+'/page/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	deletePage: (id)  => api.delete(window.backend+'/page/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	getPage: (id)  => api.get(window.backend+'/page/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	getLessonsPage: (id)  => api.get(window.backend+'/lessons/frompage/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	
	postMedia: (data)  => api.post(window.backend+'/media/',data,{headers:{session:sessionStorage.getItem('session')}}),
	putMedia: (id,data)  => api.put(window.backend+'/media/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	deleteMedia: (id)  => api.delete(window.backend+'/media/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	getMedia: (course,type)  => api.get(window.backend+'/media/'+course+'?type='+type,{headers:{session:sessionStorage.getItem('session')}}),
	








	getModalitys: () => api.get(sessionStorage.getItem('backend')+'/course_modality',{headers:{session:sessionStorage.getItem('session')}}),
	getCategorys: () => api.get(sessionStorage.getItem('backend')+'/course_category',{headers:{session:sessionStorage.getItem('session')}}),

	 
	getCouseTypes: () => api.get(sessionStorage.getItem('backend')+'/course_type',{headers:{session:sessionStorage.getItem('session')}}),
	getCouseLevels: () => api.get(sessionStorage.getItem('backend')+'/course_level',{headers:{session:sessionStorage.getItem('session')}}),

	 

	postModality: (data) => api.post(sessionStorage.getItem('backend')+'/course_modality',data,{headers:{session:sessionStorage.getItem('session')}}),
	putModality: (id,data) => api.put(sessionStorage.getItem('backend')+'/course_modality/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	deleteModality: (id) => api.delete(sessionStorage.getItem('backend')+'/course_modality/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	

	postCategory: (data) => api.post(sessionStorage.getItem('backend')+'/course_category',data,{headers:{session:sessionStorage.getItem('session')}}),
	putCategory: (id,data) => api.put(sessionStorage.getItem('backend')+'/course_category/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	deleteCategory: (id) => api.delete(sessionStorage.getItem('backend')+'/course_category/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	

	 


	postCourseType: (data) => api.post(sessionStorage.getItem('backend')+'/course_type',data,{headers:{session:sessionStorage.getItem('session')}}),
	putCourseType: (id,data) => api.put(sessionStorage.getItem('backend')+'/course_type/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	deleteCourseType: (id) => api.delete(sessionStorage.getItem('backend')+'/course_type/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	postCourseLevel: (data) => api.post(sessionStorage.getItem('backend')+'/course_level',data,{headers:{session:sessionStorage.getItem('session')}}),
	putCourseLevel: (id,data) => api.put(sessionStorage.getItem('backend')+'/course_level/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	deleteCourseLevel: (id) => api.delete(sessionStorage.getItem('backend')+'/course_level/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	checkUnits: (data) => api.post(sessionStorage.getItem('backend')+'/unit/checkunits/',data,{headers:{session:sessionStorage.getItem('session')}}),


}

export default apis;