import React, {useEffect,useState} from "react";
import {Card} from "reactstrap";
import {InputGroupText,Alert,Modal,InputGroupAddon,InputGroup,FormGroup,Input} from "reactstrap";
import api from "./../../../Api.js";

export default function CloneUnits(props){

    const [units,setUnits] = useState([]);
    const [load,setLoad] = useState(false);

    const init = (async () => {

        if(load === true){ return false; }
        setLoad(true);

        var check = await api.checkUnits({units:units.split(',')}).then(res => {

            return true;

        }).catch(err => {

            var msg = "";

            if(err.response !== undefined){
                
                if(err.response.data.msg !== undefined){ msg = err.response.data.msg; }

            }

            setLoad(false);
            window.swal.fire({title: "Ops!!!",text:msg,icon: "error"});

        });

        var unitsdds = units.split(',');
        for(let index = 0; index < unitsdds.length; index++){

            await api.duplicateUnit({course:props.course,unit:unitsdds[index]}).then(async (res) => {
            }).catch(async error => {});
            
        }

        props.success();
        
    });

    return <React.Fragment>
  
        <Modal className="modal-dialog-centered" isOpen={true} >
            <div className="modal-body p-0">
                <Card className="bg-secondary border-0 mb-0">
                    <div className="modal-header">
                        <h6 className="modal-title" id="modal-title-default">Clonar units de outros cursos</h6>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => { props.close(); }}><span aria-hidden={true}>×</span> </button>
                    </div>
                    <div className="px-lg-5 card-body">
                        <form onSubmit={(e) => { e.preventDefault(); init(); }}>
                            <h5>Informe os ids da unit separado por virgula:</h5>
                            <FormGroup className={'mb-3'} >
                                <InputGroup className="input-group-merge input-group-alternative"> 
                                <Input type="text" onChange={(e) => { setUnits(e.target.value); }} value={units} />
                                </InputGroup>
                            </FormGroup>
                            <button type="submit" class="my-4 btn btn-primary" style={{width:'100%'}}>

                                {

                                    (load === false) &&
                                    <React.Fragment>Clonar units</React.Fragment>

                                }
                                {

                                    (load === true) &&
                                    <React.Fragment>clonando...</React.Fragment>

                                }
                            
                            </button>
                        </form>
                    </div>
                </Card>
            </div>
        </Modal>

    </React.Fragment>

}