import React from "react";
import {Badge,Card,CardHeader,CardBody,Container,Row,Col,Button,Modal} from "reactstrap";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import api from "./../../Api.js";
import { SortablePane, Pane } from 'react-sortable-pane';

class ListLesson extends React.Component {

	constructor(props){

		super(props);
		this.state = {openDialogRemoveLesson:false,openModalNewLesson:false,titleModalNewLesson:"",minified:false,loadUnit:false,loadCourse:false,course:{},unit:{},lesson:[],loadLesson:false};
		
		this.newLesson = this.newLesson.bind(this);
		this.removeLessonApi = this.removeLessonApi.bind(this);
		this.removeLesson = this.removeLesson.bind(this);

		window.ListLesson = this;

	}

	//Parar cadastrar uma nova lesson
	newLesson(){ this.setState({openModalNewLesson:true,titleModalNewLesson:"Nova lesson",txtButtonModalNewLesson:"Cadastrar lesson"}); }

  	//Para pegar os dados do curso
  	async getCourse(){

  		await api.getCourse(this.props.match.params.course).then((res) => {
            
        	this.setState({course:res.data,loadCourse:true});
        	console.log(res.data);

	  	}).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);

	    	if(error.response.status === 404){ this.props.history.push('/'); }

	  	});

	}

  	//Para pegar as units
  	async getUnit(){

		await api.getUnit(this.props.match.params.unit).then((res) => {
            
        	this.setState({unit:res.data,loadUnit:true});
        	console.log(res.data);

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
        	console.log(error.response);

  		});

	}

	//Para pegar as lessons
  	async getLessons(){

		await api.getLessonUnit(this.props.match.params.unit).then((res) => {
            
        	this.setState({lesson:res.data,loadLesson:true});
        	console.log(res.data);

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
        	console.log(error.response);

  		});

	}

	removeLesson(id){

		this.setState({removeLesson:id,openDialogRemoveLesson:true});

	}

	//Para remover a lesson no banco de dados
	async removeLessonApi(){

      this.setState({openDialogRemoveLesson:false});

      await api.deleteLesson(this.state.removeLesson).then((res) => {
            
        this.getLessons();

        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso.</span><span data-notify="message">A unit foi removida.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
        this.refs.notificationAlert.notificationAlert(options);

        console.log(res.data);

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

      });

  }

	async componentDidMount(){

		await this.getCourse() ;
		await this.getUnit();
		await this.getLessons();
  
	}

	//Para atualizar a ordem das lessons
	updateOrder(order){

		order.forEach(function(item,index,array){

	        api.changeOrderLesson(item,index).then((res) => {}).catch(error => {

	            console.log(error.data);
	            console.log(error);
	            console.log(error.response);
	            return false;

	        });

	    });

	}

	changeInputMinified(event){
		
		window.app.state.lessonsMinified = event.target.checked;
		this.setState({loadLesson:false,minified:event.target.checked});
		setTimeout(function(){ this.setState({loadLesson:true}); }.bind(this), 100);

	}

	render(){

		var totHeight,styleOne,dfSize,margin;

		if(window.app.state.lessonsMinified === true){

			totHeight = this.state.lesson.length*64;
			styleOne = {height:totHeight+'px'};
			dfSize = {width:'100%',height:33};
			margin = 20;

		}else{

			totHeight = this.state.lesson.length*177;
			styleOne = {height:totHeight+'px'};
			dfSize = {width:'100%',height:157};
			margin = 10;

		}

		if(this.state.lesson.length === 0){ totHeight = '70';  styleOne = {height:totHeight+'px'}; }

    	return(
		<React.Fragment> 
			<style>{" .timeline-one-side:before{ height:"+(totHeight - 70)+"px !important; } "}</style>
			{ (this.state.loadUnit === true && this.state.loadCourse === true) &&
				<React.Fragment>
					<SimpleHeader name="Aulas" way={[{name:"Cursos",url:"/course"},{name:this.state.course.name,url:'/course/'+this.state.course.id},{name:"Aula ("+this.state.unit.name+")",url:this.props.history.location.pathname}]} buttons={<React.Fragment></React.Fragment>} />
			    	<Container className="mt--6" fluid>
			      		<Row>
				    		<Col lg="12">
				          	<Card>
				                <CardHeader className="bg-transparent">
				                	<Row>
				                	<Col xs="3"><h3 className="mb-0">Aulas</h3></Col>
				                    <Col className="text-right" xs="9">
				                    	<font style={{marginRight:'10px'}}>Minificado</font>
				                    	<label className="custom-toggle mr-1" style={{top:'8px',marginLeft:'8px',right:'10px'}}><input  {...(window.app.state.lessonsMinified ? {checked:'true'}:{})}  type="checkbox" onClick={ (event) => this.changeInputMinified(event) } /><span className="custom-toggle-slider rounded-circle" /></label>
				                    	<Button className="btn-neutral btn-round btn-icon" color="default" size="sm" onClick={ () => this.props.history.push('/course/'+this.props.match.params.course+'/unit/'+this.props.match.params.unit+'/lesson/new') } ><span className="btn-inner--icon mr-1"><i className="fas fa-plus"></i></span><span className="btn-inner--text">Nova aula</span></Button>
				                    </Col>
				                    </Row>
				                </CardHeader>
				                <CardBody style={styleOne}>
				                  <div className="timeline timeline-one-side" data-timeline-axis-style="dashed" data-timeline-content="axis" >
				                  		{
				                  			(this.state.lesson.length === 0 && this.state.loadLesson === true) &&
				                  			<React.Fragment>
				                  			<center><h3>Nenhuma aula foi encontrada.</h3></center>
				                  			</React.Fragment>
				                  		}{
				                  			(this.state.loadLesson === true) &&
			                    		 	<SortablePane direction="vertical" margin={margin} dragHandleClassName="movedor" onOrderChange={order => { this.updateOrder(order) }}>
						                    {
						                     	this.state.lesson.map((value,index) => { 
						                        //if(value.icon_file !== undefined){ iconUnit = sessionStorage.getItem('backend')+value.icon_file.url; }else{ iconUnit = ''; }
							                        return(
						                          	<Pane resizable={{x:false,y:false,xy:false}} className="paneLessonMove" key={value.id} defaultSize={dfSize}  >
							                        	<div className="timeline-block">
									                      	<Button onClick={ () => this.removeLesson(value.id) } style={{float:"right"}} color="danger" size="sm" type="button"  ><i className="far fa-trash-alt"></i></Button>
									                      	<Button onClick={ () => this.props.history.push('/course/'+this.props.match.params.course+'/unit/'+this.props.match.params.unit+'/lesson/'+value.id) } style={{float:"right"}} color="secondary" size="sm">
										                        <span className="btn-inner--icon mr-1"><i className="fas fa-pencil-alt"></i></span>
										                        <span className="btn-inner--text">Editar aula</span>
									                      	</Button>
								                      		<span className="timeline-step badge-info"><i className={window.iconsLesson[value.icon]} /></span>
								                      		<i className="fas fa-arrows-alt movedor" style={{position:'absolute',top:'11px',left:'39px',fontSize:"12px"}}></i>
								                      		<div className="timeline-content">
										                        <small className="text-muted font-weight-bold">{value.name}</small>
										                        {
										                        	(window.app.state.lessonsMinified === false) &&
										                        	<React.Fragment>
											                        	<h5 className="mt-3 mb-0">Descrição</h5>
												                        <p className="text-sm mt-1 mb-0">
												                          Nullam id dolor id nibh ultricies vehicula ut id elit.
												                          Cum sociis natoque penatibus et magnis dis parturient
												                          montes, nascetur ridiculus mus.
												                        </p>
												                        <div className="mt-3">
												                          <Badge color="success" pill>design</Badge>
												                          <Badge color="success" pill>system</Badge>
												                          <Badge color="success" pill>creative</Badge>
												                        </div>
											                        </React.Fragment>
										                        }
								                      		</div>
								                    	</div>
							                        </Pane>)
						                    	})
						                    }
						                    </SortablePane>
					                    }
  				                  </div>
				                </CardBody>
				              </Card>
				            </Col>
			        	</Row>
			    	</Container>
				</React.Fragment>
			}
	        <Modal className="modal-dialog-centered modal-danger" contentClassName="bg-gradient-danger" isOpen={this.state.openDialogRemoveLesson} >
	          <div className="modal-header">
	            <h6 className="modal-title" id="modal-title-notification">Sua atenção é requirida.</h6>
	            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.toggleModal("notificationModal")}>
	              <span aria-hidden={true} onClick={ () => this.setState({openDialogRemoveLesson:false}) }>×</span>
	            </button>
	          </div>
	          <div className="modal-body">
	            <div className="py-3 text-center">
	              <i className="fas fa-exclamation-triangle fa-x3" style={{fontSize:'45px'}}></i>
	              <h4 className="heading mt-4">VOCÊ DEVE LER ISTO!</h4>
	              <p>Se você prosseguir, todos os dados da lesson serão removidos.</p>
	            </div>
	          </div>
	          <div className="modal-footer">
	            <Button className="btn-white" color="default" type="button" onClick={ () => this.removeLessonApi() }>Ok, Remova</Button>
	            <Button className="text-white ml-auto" color="link" data-dismiss="modal" type="button" onClick={ () => this.setState({openDialogRemoveLesson:false}) }>
	              Cancelar e fechar
	            </Button>
	          </div>
	        </Modal>
		</React.Fragment>)
    }
}
export default ListLesson;