import React from "react";
import api from "./../../Api.js";
import classnames from "classnames";
import $ from 'jquery';
import {Alert,Modal,CardBody,FormGroup,InputGroupAddon,Form,Input,InputGroupText,InputGroup,Button,Card,CardHeader,Table,Container,Row,Col,UncontrolledTooltip} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";

class Tables extends React.Component {

  	constructor(props){

  		super(props);
  		this.state = {};

        this.getEvaluations = this.getEvaluations.bind(this);
        this.deleteEvaluations = this.deleteEvaluations.bind(this);
        this.putEvaluations = this.putEvaluations.bind(this);

    	window.assessment = this;

  	}

  
 

	//para atualizar os dados do grupo no sistema.
	async putGroup(){
 		 
		await api.putGroupPermission(this.state.groupEdit).then((res) => {

			this.setState({openModalNewGroup:false});

			let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">As alterações foram salvas com sucesso.</span><span data-notify="message">Informe ao usuário para relogar no sistema.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
			this.refs.notificationAlert.notificationAlert(options);

			this.getGroups();

		}).catch(error => {

			console.log(error.data);
			console.log(error);
			console.log(error.response);

			this.setState({loading:false});

				if(error.response.status === 400){
				this.setState({groupError:""});
				this.setState({groupError:error.response.data.msg});

			}

		});

	}

	deleteEvaluations(){}
 	
	putEvaluations(){}

	async getEvaluations(){

		//Para salvar as permissões no grupo
		await api.getEvaluations().then((res) => {

		}).catch(error => {

			console.log(error.data);
			console.log(error);
			console.log(error.response);

			this.setState({loading:false});

	 		if(error.response.status === 400){

		  		this.setState({groupError:""});
	  			this.setState({groupError:error.response.data.msg});

			}

		});

	}


	//para criar um novo grupo no sistem,
	async componentDidMount(){

		await this.getEvaluations();
		  
	}

	saveUser(event){

		event.preventDefault();
		if(this.state.groupEdit === ""){ this.postGroup(); }else{ this.putGroup(); }

	}

	render(){

		const handleChange = name => event => {
	        var dialogGroup = this.state.dialogGroup;
	        dialogGroup[name] = event.target.value;
	        this.setState({dialogGroup:dialogGroup});
	    }

		return(
		<React.Fragment>

		<div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
		<SimpleHeader name="Metódos de avaliação" way={[{name:"Métodos",url:"/permission"}]} buttons={<React.Fragment></React.Fragment>} />
		
        <Col md={12}>
			<Row> 
			<Col md={12}>
				<Container className="mt--6" fluid>
					<Card>
					<CardHeader className="border-0">
						<Row>
						<Col xs="6"><h3 className="mb-0">Metódos de avaliação</h3></Col>
						<Col className="text-right" xs="6" hidden>
							<Button className="btn-neutral btn-round btn-icon" color="default" href="#pablo" id="tooltip969372949" onClick={() => this.newGroup('school')} size="sm">
							<span className="btn-inner--icon mr-1"><i class="fas fa-user-plus"></i></span>
							<span className="btn-inner--text">Criar grupo</span>
							</Button>
						</Col>
						</Row>
					</CardHeader>
					<Table className="align-items-center table-flush" responsive>
						<thead className="thead-light">
						<tr>
							<th>Nome</th>
							<th>Ações</th>
						</tr>
						</thead>
						<tbody>
						{
						this.state.groups.center.map((value,index) => {

							var tooltip = "tooltip_user_key_"+value.id;
							var tooltip2 = "tooltip2_user_key_"+value.id;

							return(<tr key={value.id}>

								<td className="table-user"><b>{value.name} {value.lastName}</b></td>
								<td className="table-actions">

									<a onClick={ () => this.editGroup('center',value.id) } className="table-action" href="#pablo" id={tooltip} ><i className="fas fa-pencil-alt" /></a>
									<UncontrolledTooltip delay={0} target={tooltip}>Editar grupo</UncontrolledTooltip>

									<a onClick={ () => this.editPermission(value.id,value.groupName) } className="table-action table-action-delete" href="#pablo" id={tooltip2} ><i class="fas fa-shield-alt"></i></a>
									<UncontrolledTooltip delay={0} target={tooltip2}>Editar permissões</UncontrolledTooltip>

								</td>

							</tr>)
						})
						}
						</tbody>
					</Table>
					</Card>
				</Container>
			</Col> 
			</Row>
		</Col>

        <Modal className="modal-dialog-centered" isOpen={this.state.openModalNewGroup} >
          <div className="modal-body p-0">
            <Card className="bg-secondary border-0 mb-0">
            <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">{this.state.textDialog}</h6>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.setState({openModalNewGroup:false})}><span aria-hidden={true}>×</span> </button>
          </div>
              <CardBody className="px-lg-5">
                <Form role="form" type="post" onSubmit={ (event) => this.saveUser(event) }>
                    {
                       
                        (this.state.groupError) &&
                        <Alert color="danger" className="animated tada"><strong>Ops!</strong> {this.state.groupError}</Alert>
                    
                    }
              
					<Col md={12}>
                        <h5>Nome</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedName})} >
                        <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('name')} value={ this.state.dialogGroup.name } onFocus={() => this.setState({ focusedName: true }) } onBlur={() => this.setState({ focusedName: false }) } />
                        </InputGroup>
                        </FormGroup>
					</Col>
            
                    <div className="text-center">
                        <Button className="my-4" color="default" type="button" onClick={ () => this.setState({openModalNewGroup:false}) } >Cancelar</Button>
                        <Button className="my-4" color="primary" type="submit">{this.state.textDialog}</Button>
                    </div>

                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>



        <Modal className="modal-dialog-centered" isOpen={this.state.openModalEditPermition} >
          <div className="modal-body p-0">
            <Card className="bg-secondary border-0 mb-0">
            <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">Alterandos permissões de <strong>{this.state.editPermissionName}</strong></h6>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.setState({openModalEditPermition:false})}><span aria-hidden={true}>×</span> </button>
          </div>
              <CardBody className="px-lg-5">
                <Form role="form" type="post" onSubmit={ (event) => this.savePermition(event) }>
					{
						this.state.permitions.map((value,index) => {
							
							var id = "input_permition_"+value.id; 

							//Para mostrar apenas as permissões do grupo que estou editando no momnento 
							if(this.state.showPermitionGrupo !== value.groupName){ return <React.Fragment key={id}></React.Fragment> }

							var check = false; 
							if(this.state.permChecks.indexOf(value.id) !== -1){ check = true; }

							return(<React.Fragment key={id}>
								
								<div onChange={ (event) => {

									var permChecks = this.state.permChecks;

									console.log(event.target.checked); 
									if(event.target.checked === true){

										permChecks.push(value.id);
										this.setState({permChecks});

									}else{

										permChecks.splice(permChecks.indexOf(value.id),1);
										this.setState({permChecks});

									}

								}} className="custom-control custom-checkbox">
						          <input checked={check} className="custom-control-input inputPermission" id={id} value={value.id} type="checkbox" />
						          <label className="custom-control-label" htmlFor={id}>{value.name}</label>
						        </div>

							</React.Fragment>)

						})
					}
                  <div className="text-center">
                    <Button className="my-4" color="default" type="button" onClick={ () => this.setState({openModalEditPermition:false}) } >Cancelar</Button>
                    <Button className="my-4" color="primary" type="submit">Salvar alterações</Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>









		   
		  </React.Fragment>
		);
	}
}
export default Tables;